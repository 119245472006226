export const filters = {
    "addCollections": {},
    "wein": [
        [
            {
                "type": "shortcutfilter",
                "label": "Schnellsuche",
                "items": [
                    {
                        "label": "Bio",
                        "parameter": "bio--true",
                        "icon": {}
                    },
                    {
                        "label": "Bestseller",
                        "parameter": "essential--true",
                        "icon": {}
                    },
                    {
                        "label": "Aktionen",
                        "parameter": "onSale--true",
                        "icon": {}
                    },
                    {
                        "label": "Neuheit",
                        "parameter": "neuheit--true",
                        "icon": {}
                    },
                    {
                        "label": "Weine unter CHF 20",
                        "parameter": "preis--0f-t20",
                        "icon": {}
                    },
                    {
                        "label": "Wochentagsweine",
                        "parameter": "wochentagswein--true",
                        "icon": {}
                    },
                    {
                        "label": "Besuch kommt Weine",
                        "parameter": "besuch-kommt-wein--true",
                        "icon": {}
                    },
                    {
                        "label": "High End Weine",
                        "parameter": "high-end-wein--true",
                        "icon": {}
                    }
                ]
            },
            {
                "type": "text_filters",
                "label": "Weintyp",
                "attribute": "weintyp",
                "includeSearch": false,
                "expandable": false,
                "items": [
                    {
                        "label": "Rotwein",
                        "parameter": "weintyp--Rotwein",
                        "attribute": "weintyp",
                        "attributeValue": "Rotwein"
                    },
                    {
                        "label": "Weisswein",
                        "parameter": "weintyp--Weisswein",
                        "attribute": "weintyp",
                        "attributeValue": "Weisswein"
                    },
                    {
                        "label": "Champagner \nund Schaumweine",
                        "parameter": "weintyp--Perl-+%26+Schaumwein",
                        "attribute": "weintyp",
                        "attributeValue": "Perl-+&+Schaumwein"
                    },
                    {
                        "label": "Rosé",
                        "parameter": "weintyp--Ros%C3%A9",
                        "attribute": "weintyp",
                        "attributeValue": "Rosé"
                    },
                    {
                        "label": "Alkoholfrei",
                        "parameter": "alkoholfrei--true",
                        "attribute": "alkoholfrei",
                        "attributeValue": 1
                    }
                ]
            },
            {
                "type": "number_filter",
                "label": "Preis",
                "attribute": "preis",
                "minimum": 1,
                "maximum": 200
            }
        ],
        {
            "type": "text_filters",
            "label": "Land",
            "attribute": "land",
            "includeSearch": true,
            "expandable": true,
            "items": [
                {
                    "label": "Argentinien",
                    "parameter": "land--Argentinien",
                    "attribute": "land",
                    "attributeValue": "Argentinien"
                },
                {
                    "label": "Brasilien",
                    "parameter": "land--Brasilien",
                    "attribute": "land",
                    "attributeValue": "Brasilien"
                },
                {
                    "label": "Chile",
                    "parameter": "land--Chile",
                    "attribute": "land",
                    "attributeValue": "Chile"
                },
                {
                    "label": "Dänemark",
                    "parameter": "land--D%C3%A4nemark",
                    "attribute": "land",
                    "attributeValue": "Dänemark"
                },
                {
                    "label": "Deutschland",
                    "parameter": "land--Deutschland",
                    "attribute": "land",
                    "attributeValue": "Deutschland"
                },
                {
                    "label": "Frankreich",
                    "parameter": "land--Frankreich",
                    "attribute": "land",
                    "attributeValue": "Frankreich"
                },
                {
                    "label": "Italien",
                    "parameter": "land--Italien",
                    "attribute": "land",
                    "attributeValue": "Italien"
                },
                {
                    "label": "Neuseeland",
                    "parameter": "land--Neuseeland",
                    "attribute": "land",
                    "attributeValue": "Neuseeland"
                },
                {
                    "label": "Norwegen",
                    "parameter": "land--Norwegen",
                    "attribute": "land",
                    "attributeValue": "Norwegen"
                },
                {
                    "label": "Österreich",
                    "parameter": "land--%C3%96sterreich",
                    "attribute": "land",
                    "attributeValue": "Österreich"
                },
                {
                    "label": "Portugal",
                    "parameter": "land--Portugal",
                    "attribute": "land",
                    "attributeValue": "Portugal"
                },
                {
                    "label": "Schweiz",
                    "parameter": "land--Schweiz",
                    "attribute": "land",
                    "attributeValue": "Schweiz"
                },
                {
                    "label": "Spanien",
                    "parameter": "land--Spanien",
                    "attribute": "land",
                    "attributeValue": "Spanien"
                },
                {
                    "label": "Südafrika",
                    "parameter": "land--S%C3%BCdafrika",
                    "attribute": "land",
                    "attributeValue": "Südafrika"
                },
                {
                    "label": "USA",
                    "parameter": "land--USA",
                    "attribute": "land",
                    "attributeValue": "USA"
                }
            ],
            "searchLabel": "Länder suchen"
        },
        {
            "type": "text_filters",
            "label": "Weincharkakter",
            "attribute": "strukturtext-v2",
            "includeSearch": true,
            "expandable": true,
            "items": [
                {
                    "label": "rot, leicht",
                    "parameter": "strukturtext-v2--rot%2C%20leicht",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rot, leicht"
                },
                {
                    "label": "rot, ausgewogen",
                    "parameter": "strukturtext-v2--rot%2C%20ausgewogen",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rot, ausgewogen"
                },
                {
                    "label": "rot, kräftig",
                    "parameter": "strukturtext-v2--rot%2C%20kr%C3%A4ftig",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rot, kräftig"
                },
                {
                    "label": "rot, schwer",
                    "parameter": "strukturtext-v2--rot%2C%20schwer",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rot, schwer"
                },
                {
                    "label": "weiss, leicht",
                    "parameter": "strukturtext-v2--weiss%2C%20leicht",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "weiss, leicht"
                },
                {
                    "label": "weiss, ausgewogen",
                    "parameter": "strukturtext-v2--weiss%2C%20ausgewogen",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "weiss, ausgewogen"
                },
                {
                    "label": "weiss, expressiv",
                    "parameter": "strukturtext-v2--weiss%2C%20expressiv",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "weiss, expressiv"
                },
                {
                    "label": "weiss, süsslich",
                    "parameter": "strukturtext-v2--weiss%2C%20s%C3%BCsslich",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "weiss, süsslich"
                },
                {
                    "label": "sparkling, leicht",
                    "parameter": "strukturtext-v2--sparkling%2C%20leicht",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "sparkling, leicht"
                },
                {
                    "label": "sparkling, ausgewogen",
                    "parameter": "strukturtext-v2--sparkling%2C%20ausgewogen",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "sparkling, ausgewogen"
                },
                {
                    "label": "sparkling, expressiv",
                    "parameter": "strukturtext-v2--sparkling%2C%20expressiv",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "sparkling, expressiv"
                },
                {
                    "label": "sparkling, schwer",
                    "parameter": "strukturtext-v2--sparkling%2C%20schwer",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "sparkling, schwer"
                },
                {
                    "label": "rosé, leicht",
                    "parameter": "strukturtext-v2--ros%C3%A9%2C%20leicht",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rosé, leicht"
                },
                {
                    "label": "rosé, ausgewogen",
                    "parameter": "strukturtext-v2--ros%C3%A9%2C%20ausgewogen",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rosé, ausgewogen"
                },
                {
                    "label": "rosé, kräftig",
                    "parameter": "strukturtext-v2--ros%C3%A9%2C%20kr%C3%A4ftig",
                    "attribute": "strukturtext-v2",
                    "attributeValue": "rosé, kräftig"
                }
            ]
        },
        {
            "type": "text_filters",
            "label": "Region",
            "attribute": "appellation",
            "includeSearch": true,
            "expandable": true,
            "items": [
                {
                    "label": "Aargau",
                    "parameter": "appellation--Aargau",
                    "attribute": "appellation",
                    "attributeValue": "Aargau"
                },
                {
                    "label": "Abruzzo",
                    "parameter": "appellation--Abruzzo",
                    "attribute": "appellation",
                    "attributeValue": "Abruzzo"
                },
                {
                    "label": "Alba",
                    "parameter": "appellation--Alba",
                    "attribute": "appellation",
                    "attributeValue": "Alba"
                },
                {
                    "label": "Alsace",
                    "parameter": "appellation--Alsace",
                    "attribute": "appellation",
                    "attributeValue": "Alsace"
                },
                {
                    "label": "Alto Adige",
                    "parameter": "appellation--Alto%20Adige",
                    "attribute": "appellation",
                    "attributeValue": "Alto Adige"
                },
                {
                    "label": "Apalta Valley",
                    "parameter": "appellation--Apalta%20Valley",
                    "attribute": "appellation",
                    "attributeValue": "Apalta Valley"
                },
                {
                    "label": "Asti",
                    "parameter": "appellation--Asti",
                    "attribute": "appellation",
                    "attributeValue": "Asti"
                },
                {
                    "label": "Barolo",
                    "parameter": "appellation--Barolo",
                    "attribute": "appellation",
                    "attributeValue": "Barolo"
                },
                {
                    "label": "Basilicata",
                    "parameter": "appellation--Basilicata",
                    "attribute": "appellation",
                    "attributeValue": "Basilicata"
                },
                {
                    "label": "Beaujolais-Villages",
                    "parameter": "appellation--Beaujolais-Villages",
                    "attribute": "appellation",
                    "attributeValue": "Beaujolais-Villages"
                },
                {
                    "label": "Bodensee",
                    "parameter": "appellation--Bodensee",
                    "attribute": "appellation",
                    "attributeValue": "Bodensee"
                },
                {
                    "label": "Bolgheri",
                    "parameter": "appellation--Bolgheri",
                    "attribute": "appellation",
                    "attributeValue": "Bolgheri"
                },
                {
                    "label": "Bordeaux",
                    "parameter": "appellation--Bordeaux",
                    "attribute": "appellation",
                    "attributeValue": "Bordeaux"
                },
                {
                    "label": "Bordeaux Supérieur",
                    "parameter": "appellation--Bordeaux%20Sup%C3%A9rieur",
                    "attribute": "appellation",
                    "attributeValue": "Bordeaux Supérieur"
                },
                {
                    "label": "Bourgogne",
                    "parameter": "appellation--Bourgogne",
                    "attribute": "appellation",
                    "attributeValue": "Bourgogne"
                },
                {
                    "label": "Burgenland",
                    "parameter": "appellation--Burgenland",
                    "attribute": "appellation",
                    "attributeValue": "Burgenland"
                },
                {
                    "label": "California",
                    "parameter": "appellation--California",
                    "attribute": "appellation",
                    "attributeValue": "California"
                },
                {
                    "label": "Campo de Borja",
                    "parameter": "appellation--Campo%20de%20Borja",
                    "attribute": "appellation",
                    "attributeValue": "Campo de Borja"
                },
                {
                    "label": "Casablanca Valley",
                    "parameter": "appellation--Casablanca%20Valley",
                    "attribute": "appellation",
                    "attributeValue": "Casablanca Valley"
                },
                {
                    "label": "Castilla (VdT)",
                    "parameter": "appellation--Castilla%20(VdT)",
                    "attribute": "appellation",
                    "attributeValue": "Castilla (VdT)"
                },
                {
                    "label": "Castilla La Mancha",
                    "parameter": "appellation--Castilla%20La%20Mancha",
                    "attribute": "appellation",
                    "attributeValue": "Castilla La Mancha"
                },
                {
                    "label": "Castilla y León",
                    "parameter": "appellation--Castilla%20y%20Le%C3%B3n",
                    "attribute": "appellation",
                    "attributeValue": "Castilla y León"
                },
                {
                    "label": "Cava",
                    "parameter": "appellation--Cava",
                    "attribute": "appellation",
                    "attributeValue": "Cava"
                },
                {
                    "label": "Central Valley",
                    "parameter": "appellation--Central%20Valley",
                    "attribute": "appellation",
                    "attributeValue": "Central Valley"
                },
                {
                    "label": "Chablis",
                    "parameter": "appellation--Chablis",
                    "attribute": "appellation",
                    "attributeValue": "Chablis"
                },
                {
                    "label": "Champagne",
                    "parameter": "appellation--Champagne",
                    "attribute": "appellation",
                    "attributeValue": "Champagne"
                },
                {
                    "label": "Châteauneuf-du-Pape",
                    "parameter": "appellation--Ch%C3%A2teauneuf-du-Pape",
                    "attribute": "appellation",
                    "attributeValue": "Châteauneuf-du-Pape"
                },
                {
                    "label": "Chianti",
                    "parameter": "appellation--Chianti",
                    "attribute": "appellation",
                    "attributeValue": "Chianti"
                },
                {
                    "label": "Colchagua Valley",
                    "parameter": "appellation--Colchagua%20Valley",
                    "attribute": "appellation",
                    "attributeValue": "Colchagua Valley"
                },
                {
                    "label": "Cornas",
                    "parameter": "appellation--Cornas",
                    "attribute": "appellation",
                    "attributeValue": "Cornas"
                },
                {
                    "label": "Côte Chalonnaise",
                    "parameter": "appellation--C%C3%B4te%20Chalonnaise",
                    "attribute": "appellation",
                    "attributeValue": "Côte Chalonnaise"
                },
                {
                    "label": "Côte de Beaune",
                    "parameter": "appellation--C%C3%B4te%20de%20Beaune",
                    "attribute": "appellation",
                    "attributeValue": "Côte de Beaune"
                },
                {
                    "label": "Côte de Nuits",
                    "parameter": "appellation--C%C3%B4te%20de%20Nuits",
                    "attribute": "appellation",
                    "attributeValue": "Côte de Nuits"
                },
                {
                    "label": "Côtes de Provence",
                    "parameter": "appellation--C%C3%B4tes%20de%20Provence",
                    "attribute": "appellation",
                    "attributeValue": "Côtes de Provence"
                },
                {
                    "label": "Crozes-Hermitage",
                    "parameter": "appellation--Crozes-Hermitage",
                    "attribute": "appellation",
                    "attributeValue": "Crozes-Hermitage"
                },
                {
                    "label": "Douro",
                    "parameter": "appellation--Douro",
                    "attribute": "appellation",
                    "attributeValue": "Douro"
                },
                {
                    "label": "Faugères",
                    "parameter": "appellation--Faug%C3%A8res",
                    "attribute": "appellation",
                    "attributeValue": "Faugères"
                },
                {
                    "label": "Fleurie",
                    "parameter": "appellation--Fleurie",
                    "attribute": "appellation",
                    "attributeValue": "Fleurie"
                },
                {
                    "label": "Franciacorta",
                    "parameter": "appellation--Franciacorta",
                    "attribute": "appellation",
                    "attributeValue": "Franciacorta"
                },
                {
                    "label": "Frankreich",
                    "parameter": "appellation--Frankreich",
                    "attribute": "appellation",
                    "attributeValue": "Frankreich"
                },
                {
                    "label": "Gallura",
                    "parameter": "appellation--Gallura",
                    "attribute": "appellation",
                    "attributeValue": "Gallura"
                },
                {
                    "label": "Genf",
                    "parameter": "appellation--Genf",
                    "attribute": "appellation",
                    "attributeValue": "Genf"
                },
                {
                    "label": "Graubünden",
                    "parameter": "appellation--Graub%C3%BCnden",
                    "attribute": "appellation",
                    "attributeValue": "Graubünden"
                },
                {
                    "label": "Hermitage",
                    "parameter": "appellation--Hermitage",
                    "attribute": "appellation",
                    "attributeValue": "Hermitage"
                },
                {
                    "label": "Hessen",
                    "parameter": "appellation--Hessen",
                    "attribute": "appellation",
                    "attributeValue": "Hessen"
                },
                {
                    "label": "Italien",
                    "parameter": "appellation--Italien",
                    "attribute": "appellation",
                    "attributeValue": "Italien"
                },
                {
                    "label": "Kopenhagen",
                    "parameter": "appellation--Kopenhagen",
                    "attribute": "appellation",
                    "attributeValue": "Kopenhagen"
                },
                {
                    "label": "La Clape",
                    "parameter": "appellation--La%20Clape",
                    "attribute": "appellation",
                    "attributeValue": "La Clape"
                },
                {
                    "label": "La Mancha",
                    "parameter": "appellation--La%20Mancha",
                    "attribute": "appellation",
                    "attributeValue": "La Mancha"
                },
                {
                    "label": "Langhe",
                    "parameter": "appellation--Langhe",
                    "attribute": "appellation",
                    "attributeValue": "Langhe"
                },
                {
                    "label": "Maconnais",
                    "parameter": "appellation--Maconnais",
                    "attribute": "appellation",
                    "attributeValue": "Maconnais"
                },
                {
                    "label": "Manduria",
                    "parameter": "appellation--Manduria",
                    "attribute": "appellation",
                    "attributeValue": "Manduria"
                },
                {
                    "label": "Maremma",
                    "parameter": "appellation--Maremma",
                    "attribute": "appellation",
                    "attributeValue": "Maremma"
                },
                {
                    "label": "Marlborough",
                    "parameter": "appellation--Marlborough",
                    "attribute": "appellation",
                    "attributeValue": "Marlborough"
                },
                {
                    "label": "Méditerranée",
                    "parameter": "appellation--M%C3%A9diterran%C3%A9e",
                    "attribute": "appellation",
                    "attributeValue": "Méditerranée"
                },
                {
                    "label": "Médoc",
                    "parameter": "appellation--M%C3%A9doc",
                    "attribute": "appellation",
                    "attributeValue": "Médoc"
                },
                {
                    "label": "Mendoza",
                    "parameter": "appellation--Mendoza",
                    "attribute": "appellation",
                    "attributeValue": "Mendoza"
                },
                {
                    "label": "Minervois",
                    "parameter": "appellation--Minervois",
                    "attribute": "appellation",
                    "attributeValue": "Minervois"
                },
                {
                    "label": "Montalcino",
                    "parameter": "appellation--Montalcino",
                    "attribute": "appellation",
                    "attributeValue": "Montalcino"
                },
                {
                    "label": "Montepulciano",
                    "parameter": "appellation--Montepulciano",
                    "attribute": "appellation",
                    "attributeValue": "Montepulciano"
                },
                {
                    "label": "Moulin-à-Vent",
                    "parameter": "appellation--Moulin-%C3%A0-Vent",
                    "attribute": "appellation",
                    "attributeValue": "Moulin-à-Vent"
                },
                {
                    "label": "Moulis",
                    "parameter": "appellation--Moulis",
                    "attribute": "appellation",
                    "attributeValue": "Moulis"
                },
                {
                    "label": "Neuenburg",
                    "parameter": "appellation--Neuenburg",
                    "attribute": "appellation",
                    "attributeValue": "Neuenburg"
                },
                {
                    "label": "Neusiedlersee",
                    "parameter": "appellation--Neusiedlersee",
                    "attribute": "appellation",
                    "attributeValue": "Neusiedlersee"
                },
                {
                    "label": "Niederösterreich",
                    "parameter": "appellation--Nieder%C3%B6sterreich",
                    "attribute": "appellation",
                    "attributeValue": "Niederösterreich"
                },
                {
                    "label": "Norwegen",
                    "parameter": "appellation--Norwegen",
                    "attribute": "appellation",
                    "attributeValue": "Norwegen"
                },
                {
                    "label": "Pauillac",
                    "parameter": "appellation--Pauillac",
                    "attribute": "appellation",
                    "attributeValue": "Pauillac"
                },
                {
                    "label": "Pays d'Oc",
                    "parameter": "appellation--Pays%20d'Oc",
                    "attribute": "appellation",
                    "attributeValue": "Pays d'Oc"
                },
                {
                    "label": "Penedés",
                    "parameter": "appellation--Pened%C3%A9s",
                    "attribute": "appellation",
                    "attributeValue": "Penedés"
                },
                {
                    "label": "Pfalz",
                    "parameter": "appellation--Pfalz",
                    "attribute": "appellation",
                    "attributeValue": "Pfalz"
                },
                {
                    "label": "Piemonte",
                    "parameter": "appellation--Piemonte",
                    "attribute": "appellation",
                    "attributeValue": "Piemonte"
                },
                {
                    "label": "Porto",
                    "parameter": "appellation--Porto",
                    "attribute": "appellation",
                    "attributeValue": "Porto"
                },
                {
                    "label": "Priorat",
                    "parameter": "appellation--Priorat",
                    "attribute": "appellation",
                    "attributeValue": "Priorat"
                },
                {
                    "label": "Rías Baixas",
                    "parameter": "appellation--R%C3%ADas%20Baixas",
                    "attribute": "appellation",
                    "attributeValue": "Rías Baixas"
                },
                {
                    "label": "Ribera del Duero",
                    "parameter": "appellation--Ribera%20del%20Duero",
                    "attribute": "appellation",
                    "attributeValue": "Ribera del Duero"
                },
                {
                    "label": "Rioja",
                    "parameter": "appellation--Rioja",
                    "attribute": "appellation",
                    "attributeValue": "Rioja"
                },
                {
                    "label": "Roero",
                    "parameter": "appellation--Roero",
                    "attribute": "appellation",
                    "attributeValue": "Roero"
                },
                {
                    "label": "Rueda",
                    "parameter": "appellation--Rueda",
                    "attribute": "appellation",
                    "attributeValue": "Rueda"
                },
                {
                    "label": "Saint Emilion",
                    "parameter": "appellation--Saint%20Emilion",
                    "attribute": "appellation",
                    "attributeValue": "Saint Emilion"
                },
                {
                    "label": "Saint Estèphe",
                    "parameter": "appellation--Saint%20Est%C3%A8phe",
                    "attribute": "appellation",
                    "attributeValue": "Saint Estèphe"
                },
                {
                    "label": "Saint Julien",
                    "parameter": "appellation--Saint%20Julien",
                    "attribute": "appellation",
                    "attributeValue": "Saint Julien"
                },
                {
                    "label": "Saint-Amour",
                    "parameter": "appellation--Saint-Amour",
                    "attribute": "appellation",
                    "attributeValue": "Saint-Amour"
                },
                {
                    "label": "Salento",
                    "parameter": "appellation--Salento",
                    "attribute": "appellation",
                    "attributeValue": "Salento"
                },
                {
                    "label": "Sancerre",
                    "parameter": "appellation--Sancerre",
                    "attribute": "appellation",
                    "attributeValue": "Sancerre"
                },
                {
                    "label": "Sardegna",
                    "parameter": "appellation--Sardegna",
                    "attribute": "appellation",
                    "attributeValue": "Sardegna"
                },
                {
                    "label": "Sauternes",
                    "parameter": "appellation--Sauternes",
                    "attribute": "appellation",
                    "attributeValue": "Sauternes"
                },
                {
                    "label": "Schaffhausen",
                    "parameter": "appellation--Schaffhausen",
                    "attribute": "appellation",
                    "attributeValue": "Schaffhausen"
                },
                {
                    "label": "Sicilia",
                    "parameter": "appellation--Sicilia",
                    "attribute": "appellation",
                    "attributeValue": "Sicilia"
                },
                {
                    "label": "Soave",
                    "parameter": "appellation--Soave",
                    "attribute": "appellation",
                    "attributeValue": "Soave"
                },
                {
                    "label": "Somontano",
                    "parameter": "appellation--Somontano",
                    "attribute": "appellation",
                    "attributeValue": "Somontano"
                },
                {
                    "label": "Spanien",
                    "parameter": "appellation--Spanien",
                    "attribute": "appellation",
                    "attributeValue": "Spanien"
                },
                {
                    "label": "Stellenbosch",
                    "parameter": "appellation--Stellenbosch",
                    "attribute": "appellation",
                    "attributeValue": "Stellenbosch"
                },
                {
                    "label": "Südsteiermark",
                    "parameter": "appellation--S%C3%BCdsteiermark",
                    "attribute": "appellation",
                    "attributeValue": "Südsteiermark"
                },
                {
                    "label": "Terra Alta",
                    "parameter": "appellation--Terra%20Alta",
                    "attribute": "appellation",
                    "attributeValue": "Terra Alta"
                },
                {
                    "label": "Tessin",
                    "parameter": "appellation--Tessin",
                    "attribute": "appellation",
                    "attributeValue": "Tessin"
                },
                {
                    "label": "Toscana",
                    "parameter": "appellation--Toscana",
                    "attribute": "appellation",
                    "attributeValue": "Toscana"
                },
                {
                    "label": "Treviso",
                    "parameter": "appellation--Treviso",
                    "attribute": "appellation",
                    "attributeValue": "Treviso"
                },
                {
                    "label": "Valdobbiadene",
                    "parameter": "appellation--Valdobbiadene",
                    "attribute": "appellation",
                    "attributeValue": "Valdobbiadene"
                },
                {
                    "label": "Vale dos Vinhedos",
                    "parameter": "appellation--Vale%20dos%20Vinhedos",
                    "attribute": "appellation",
                    "attributeValue": "Vale dos Vinhedos"
                },
                {
                    "label": "Valpolicella",
                    "parameter": "appellation--Valpolicella",
                    "attribute": "appellation",
                    "attributeValue": "Valpolicella"
                },
                {
                    "label": "Valtellina",
                    "parameter": "appellation--Valtellina",
                    "attribute": "appellation",
                    "attributeValue": "Valtellina"
                },
                {
                    "label": "Veneto/Venezie",
                    "parameter": "appellation--Veneto%2FVenezie",
                    "attribute": "appellation",
                    "attributeValue": "Veneto/Venezie"
                },
                {
                    "label": "Waadtland",
                    "parameter": "appellation--Waadtland",
                    "attribute": "appellation",
                    "attributeValue": "Waadtland"
                },
                {
                    "label": "Wallis",
                    "parameter": "appellation--Wallis",
                    "attribute": "appellation",
                    "attributeValue": "Wallis"
                }
            ],
            "searchLabel": "Regionen suchen"
        },
        {
            "type": "text_filters",
            "label": "Foodpairings",
            "attribute": "passt-zu",
            "includeSearch": true,
            "expandable": true,
            "items": [
                {
                    "label": "Amuse-bouche/Hors d'oeuvre",
                    "parameter": "passt-zu--Amuse-bouche%2FHors%20d'oeuvre",
                    "attribute": "passt-zu",
                    "attributeValue": "Amuse-bouche/Hors d'oeuvre"
                },
                {
                    "label": "Antipasti",
                    "parameter": "passt-zu--Antipasti",
                    "attribute": "passt-zu",
                    "attributeValue": "Antipasti"
                },
                {
                    "label": "Apéro/Aperitif",
                    "parameter": "passt-zu--Ap%C3%A9ro%2FAperitif",
                    "attribute": "passt-zu",
                    "attributeValue": "Apéro/Aperitif"
                },
                {
                    "label": "Apfelkuchen",
                    "parameter": "passt-zu--Apfelkuchen",
                    "attribute": "passt-zu",
                    "attributeValue": "Apfelkuchen"
                },
                {
                    "label": "Apfelstrudel",
                    "parameter": "passt-zu--Apfelstrudel",
                    "attribute": "passt-zu",
                    "attributeValue": "Apfelstrudel"
                },
                {
                    "label": "Asiatische Küche",
                    "parameter": "passt-zu--Asiatische%20K%C3%BCche",
                    "attribute": "passt-zu",
                    "attributeValue": "Asiatische Küche"
                },
                {
                    "label": "Aufläufe",
                    "parameter": "passt-zu--Aufl%C3%A4ufe",
                    "attribute": "passt-zu",
                    "attributeValue": "Aufläufe"
                },
                {
                    "label": "baked potatoes",
                    "parameter": "passt-zu--baked%20potatoes",
                    "attribute": "passt-zu",
                    "attributeValue": "baked potatoes"
                },
                {
                    "label": "Beefburger",
                    "parameter": "passt-zu--Beefburger",
                    "attribute": "passt-zu",
                    "attributeValue": "Beefburger"
                },
                {
                    "label": "Beeren",
                    "parameter": "passt-zu--Beeren",
                    "attribute": "passt-zu",
                    "attributeValue": "Beeren"
                },
                {
                    "label": "Bergkäse/Alpkäse",
                    "parameter": "passt-zu--Bergk%C3%A4se%2FAlpk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Bergkäse/Alpkäse"
                },
                {
                    "label": "Berner Platte",
                    "parameter": "passt-zu--Berner%20Platte",
                    "attribute": "passt-zu",
                    "attributeValue": "Berner Platte"
                },
                {
                    "label": "Blauschimmelkäse",
                    "parameter": "passt-zu--Blauschimmelk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Blauschimmelkäse"
                },
                {
                    "label": "Bratkartoffel",
                    "parameter": "passt-zu--Bratkartoffel",
                    "attribute": "passt-zu",
                    "attributeValue": "Bratkartoffel"
                },
                {
                    "label": "Bresaola",
                    "parameter": "passt-zu--Bresaola",
                    "attribute": "passt-zu",
                    "attributeValue": "Bresaola"
                },
                {
                    "label": "Brie",
                    "parameter": "passt-zu--Brie",
                    "attribute": "passt-zu",
                    "attributeValue": "Brie"
                },
                {
                    "label": "Brot",
                    "parameter": "passt-zu--Brot",
                    "attribute": "passt-zu",
                    "attributeValue": "Brot"
                },
                {
                    "label": "Bruschetta",
                    "parameter": "passt-zu--Bruschetta",
                    "attribute": "passt-zu",
                    "attributeValue": "Bruschetta"
                },
                {
                    "label": "Brustspitz",
                    "parameter": "passt-zu--Brustspitz",
                    "attribute": "passt-zu",
                    "attributeValue": "Brustspitz"
                },
                {
                    "label": "Bündner Gerstensuppe",
                    "parameter": "passt-zu--B%C3%BCndner%20Gerstensuppe",
                    "attribute": "passt-zu",
                    "attributeValue": "Bündner Gerstensuppe"
                },
                {
                    "label": "Bündnerfleisch",
                    "parameter": "passt-zu--B%C3%BCndnerfleisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Bündnerfleisch"
                },
                {
                    "label": "Bündnerplatte",
                    "parameter": "passt-zu--B%C3%BCndnerplatte",
                    "attribute": "passt-zu",
                    "attributeValue": "Bündnerplatte"
                },
                {
                    "label": "Camembert",
                    "parameter": "passt-zu--Camembert",
                    "attribute": "passt-zu",
                    "attributeValue": "Camembert"
                },
                {
                    "label": "Canapé",
                    "parameter": "passt-zu--Canap%C3%A9",
                    "attribute": "passt-zu",
                    "attributeValue": "Canapé"
                },
                {
                    "label": "Cannelloni",
                    "parameter": "passt-zu--Cannelloni",
                    "attribute": "passt-zu",
                    "attributeValue": "Cannelloni"
                },
                {
                    "label": "Cantucci",
                    "parameter": "passt-zu--Cantucci",
                    "attribute": "passt-zu",
                    "attributeValue": "Cantucci"
                },
                {
                    "label": "Charcuterie",
                    "parameter": "passt-zu--Charcuterie",
                    "attribute": "passt-zu",
                    "attributeValue": "Charcuterie"
                },
                {
                    "label": "Châteaubriand",
                    "parameter": "passt-zu--Ch%C3%A2teaubriand",
                    "attribute": "passt-zu",
                    "attributeValue": "Châteaubriand"
                },
                {
                    "label": "Comté",
                    "parameter": "passt-zu--Comt%C3%A9",
                    "attribute": "passt-zu",
                    "attributeValue": "Comté"
                },
                {
                    "label": "Coppa",
                    "parameter": "passt-zu--Coppa",
                    "attribute": "passt-zu",
                    "attributeValue": "Coppa"
                },
                {
                    "label": "Coq au vin",
                    "parameter": "passt-zu--Coq%20au%20vin",
                    "attribute": "passt-zu",
                    "attributeValue": "Coq au vin"
                },
                {
                    "label": "Cordon Bleu",
                    "parameter": "passt-zu--Cordon%20Bleu",
                    "attribute": "passt-zu",
                    "attributeValue": "Cordon Bleu"
                },
                {
                    "label": "Crêpes",
                    "parameter": "passt-zu--Cr%C3%AApes",
                    "attribute": "passt-zu",
                    "attributeValue": "Crêpes"
                },
                {
                    "label": "Crevettencocktail",
                    "parameter": "passt-zu--Crevettencocktail",
                    "attribute": "passt-zu",
                    "attributeValue": "Crevettencocktail"
                },
                {
                    "label": "Crevettensalat",
                    "parameter": "passt-zu--Crevettensalat",
                    "attribute": "passt-zu",
                    "attributeValue": "Crevettensalat"
                },
                {
                    "label": "Currygerichte",
                    "parameter": "passt-zu--Currygerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Currygerichte"
                },
                {
                    "label": "Dorade",
                    "parameter": "passt-zu--Dorade",
                    "attribute": "passt-zu",
                    "attributeValue": "Dorade"
                },
                {
                    "label": "Dörrfrüchte",
                    "parameter": "passt-zu--D%C3%B6rrfr%C3%BCchte",
                    "attribute": "passt-zu",
                    "attributeValue": "Dörrfrüchte"
                },
                {
                    "label": "Eglifilet",
                    "parameter": "passt-zu--Eglifilet",
                    "attribute": "passt-zu",
                    "attributeValue": "Eglifilet"
                },
                {
                    "label": "Eiervorspeisen",
                    "parameter": "passt-zu--Eiervorspeisen",
                    "attribute": "passt-zu",
                    "attributeValue": "Eiervorspeisen"
                },
                {
                    "label": "Eintopfgerichte",
                    "parameter": "passt-zu--Eintopfgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Eintopfgerichte"
                },
                {
                    "label": "Emmentaler",
                    "parameter": "passt-zu--Emmentaler",
                    "attribute": "passt-zu",
                    "attributeValue": "Emmentaler"
                },
                {
                    "label": "Ente",
                    "parameter": "passt-zu--Ente",
                    "attribute": "passt-zu",
                    "attributeValue": "Ente"
                },
                {
                    "label": "Entenbrust",
                    "parameter": "passt-zu--Entenbrust",
                    "attribute": "passt-zu",
                    "attributeValue": "Entenbrust"
                },
                {
                    "label": "Fasan",
                    "parameter": "passt-zu--Fasan",
                    "attribute": "passt-zu",
                    "attributeValue": "Fasan"
                },
                {
                    "label": "Federwild",
                    "parameter": "passt-zu--Federwild",
                    "attribute": "passt-zu",
                    "attributeValue": "Federwild"
                },
                {
                    "label": "Feta",
                    "parameter": "passt-zu--Feta",
                    "attribute": "passt-zu",
                    "attributeValue": "Feta"
                },
                {
                    "label": "Fischgerichte",
                    "parameter": "passt-zu--Fischgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Fischgerichte"
                },
                {
                    "label": "Fischterrine",
                    "parameter": "passt-zu--Fischterrine",
                    "attribute": "passt-zu",
                    "attributeValue": "Fischterrine"
                },
                {
                    "label": "Fladenbrot",
                    "parameter": "passt-zu--Fladenbrot",
                    "attribute": "passt-zu",
                    "attributeValue": "Fladenbrot"
                },
                {
                    "label": "Fleisch-Kasserolle",
                    "parameter": "passt-zu--Fleisch-Kasserolle",
                    "attribute": "passt-zu",
                    "attributeValue": "Fleisch-Kasserolle"
                },
                {
                    "label": "Fleischgerichte",
                    "parameter": "passt-zu--Fleischgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Fleischgerichte"
                },
                {
                    "label": "Fleischpastete/Fleisch-Pie",
                    "parameter": "passt-zu--Fleischpastete%2FFleisch-Pie",
                    "attribute": "passt-zu",
                    "attributeValue": "Fleischpastete/Fleisch-Pie"
                },
                {
                    "label": "Fleischplatte",
                    "parameter": "passt-zu--Fleischplatte",
                    "attribute": "passt-zu",
                    "attributeValue": "Fleischplatte"
                },
                {
                    "label": "Fleischterrine",
                    "parameter": "passt-zu--Fleischterrine",
                    "attribute": "passt-zu",
                    "attributeValue": "Fleischterrine"
                },
                {
                    "label": "Fleischtopf",
                    "parameter": "passt-zu--Fleischtopf",
                    "attribute": "passt-zu",
                    "attributeValue": "Fleischtopf"
                },
                {
                    "label": "Fondue",
                    "parameter": "passt-zu--Fondue",
                    "attribute": "passt-zu",
                    "attributeValue": "Fondue"
                },
                {
                    "label": "Fondue bourguignonne",
                    "parameter": "passt-zu--Fondue%20bourguignonne",
                    "attribute": "passt-zu",
                    "attributeValue": "Fondue bourguignonne"
                },
                {
                    "label": "Fondue chinoise",
                    "parameter": "passt-zu--Fondue%20chinoise",
                    "attribute": "passt-zu",
                    "attributeValue": "Fondue chinoise"
                },
                {
                    "label": "Forelle",
                    "parameter": "passt-zu--Forelle",
                    "attribute": "passt-zu",
                    "attributeValue": "Forelle"
                },
                {
                    "label": "Forelle Müllerinart",
                    "parameter": "passt-zu--Forelle%20M%C3%BCllerinart",
                    "attribute": "passt-zu",
                    "attributeValue": "Forelle Müllerinart"
                },
                {
                    "label": "Frischkäse",
                    "parameter": "passt-zu--Frischk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Frischkäse"
                },
                {
                    "label": "frittierte Eglifilet",
                    "parameter": "passt-zu--frittierte%20Eglifilet",
                    "attribute": "passt-zu",
                    "attributeValue": "frittierte Eglifilet"
                },
                {
                    "label": "frittierte Pilze",
                    "parameter": "passt-zu--frittierte%20Pilze",
                    "attribute": "passt-zu",
                    "attributeValue": "frittierte Pilze"
                },
                {
                    "label": "Früchte",
                    "parameter": "passt-zu--Fr%C3%BCchte",
                    "attribute": "passt-zu",
                    "attributeValue": "Früchte"
                },
                {
                    "label": "Früchtegratin",
                    "parameter": "passt-zu--Fr%C3%BCchtegratin",
                    "attribute": "passt-zu",
                    "attributeValue": "Früchtegratin"
                },
                {
                    "label": "Früchtewähe",
                    "parameter": "passt-zu--Fr%C3%BCchtew%C3%A4he",
                    "attribute": "passt-zu",
                    "attributeValue": "Früchtewähe"
                },
                {
                    "label": "Fruchtsalat",
                    "parameter": "passt-zu--Fruchtsalat",
                    "attribute": "passt-zu",
                    "attributeValue": "Fruchtsalat"
                },
                {
                    "label": "Frühlingsrolle",
                    "parameter": "passt-zu--Fr%C3%BChlingsrolle",
                    "attribute": "passt-zu",
                    "attributeValue": "Frühlingsrolle"
                },
                {
                    "label": "Gambas vom Grill",
                    "parameter": "passt-zu--Gambas%20vom%20Grill",
                    "attribute": "passt-zu",
                    "attributeValue": "Gambas vom Grill"
                },
                {
                    "label": "Gans",
                    "parameter": "passt-zu--Gans",
                    "attribute": "passt-zu",
                    "attributeValue": "Gans"
                },
                {
                    "label": "Gänsebraten",
                    "parameter": "passt-zu--G%C3%A4nsebraten",
                    "attribute": "passt-zu",
                    "attributeValue": "Gänsebraten"
                },
                {
                    "label": "Gänseleber",
                    "parameter": "passt-zu--G%C3%A4nseleber",
                    "attribute": "passt-zu",
                    "attributeValue": "Gänseleber"
                },
                {
                    "label": "Garnelen",
                    "parameter": "passt-zu--Garnelen",
                    "attribute": "passt-zu",
                    "attributeValue": "Garnelen"
                },
                {
                    "label": "Gazpacho",
                    "parameter": "passt-zu--Gazpacho",
                    "attribute": "passt-zu",
                    "attributeValue": "Gazpacho"
                },
                {
                    "label": "Gebundene Suppen",
                    "parameter": "passt-zu--Gebundene%20Suppen",
                    "attribute": "passt-zu",
                    "attributeValue": "Gebundene Suppen"
                },
                {
                    "label": "Geflügelgerichte",
                    "parameter": "passt-zu--Gefl%C3%BCgelgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Geflügelgerichte"
                },
                {
                    "label": "Geflügelpastete/Geflügel-Pie",
                    "parameter": "passt-zu--Gefl%C3%BCgelpastete%2FGefl%C3%BCgel-Pie",
                    "attribute": "passt-zu",
                    "attributeValue": "Geflügelpastete/Geflügel-Pie"
                },
                {
                    "label": "Geflügelterrine",
                    "parameter": "passt-zu--Gefl%C3%BCgelterrine",
                    "attribute": "passt-zu",
                    "attributeValue": "Geflügelterrine"
                },
                {
                    "label": "Gefrorene Süssspeisen",
                    "parameter": "passt-zu--Gefrorene%20S%C3%BCssspeisen",
                    "attribute": "passt-zu",
                    "attributeValue": "Gefrorene Süssspeisen"
                },
                {
                    "label": "gefüllte Kalbsbrust",
                    "parameter": "passt-zu--gef%C3%BCllte%20Kalbsbrust",
                    "attribute": "passt-zu",
                    "attributeValue": "gefüllte Kalbsbrust"
                },
                {
                    "label": "gefüllte Teigtaschen",
                    "parameter": "passt-zu--gef%C3%BCllte%20Teigtaschen",
                    "attribute": "passt-zu",
                    "attributeValue": "gefüllte Teigtaschen"
                },
                {
                    "label": "gemischter Salat",
                    "parameter": "passt-zu--gemischter%20Salat",
                    "attribute": "passt-zu",
                    "attributeValue": "gemischter Salat"
                },
                {
                    "label": "Gemüse-Dips",
                    "parameter": "passt-zu--Gem%C3%BCse-Dips",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüse-Dips"
                },
                {
                    "label": "Gemüse-Schmorgerichte",
                    "parameter": "passt-zu--Gem%C3%BCse-Schmorgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüse-Schmorgerichte"
                },
                {
                    "label": "Gemüse-Soufflé",
                    "parameter": "passt-zu--Gem%C3%BCse-Souffl%C3%A9",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüse-Soufflé"
                },
                {
                    "label": "Gemüseauflauf",
                    "parameter": "passt-zu--Gem%C3%BCseauflauf",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüseauflauf"
                },
                {
                    "label": "Gemüseeintopf",
                    "parameter": "passt-zu--Gem%C3%BCseeintopf",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüseeintopf"
                },
                {
                    "label": "Gemüsegerichte",
                    "parameter": "passt-zu--Gem%C3%BCsegerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüsegerichte"
                },
                {
                    "label": "Gemüserisotto",
                    "parameter": "passt-zu--Gem%C3%BCserisotto",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüserisotto"
                },
                {
                    "label": "Gemüseroulade",
                    "parameter": "passt-zu--Gem%C3%BCseroulade",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüseroulade"
                },
                {
                    "label": "Gemüseteller",
                    "parameter": "passt-zu--Gem%C3%BCseteller",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüseteller"
                },
                {
                    "label": "Gemüseterrine",
                    "parameter": "passt-zu--Gem%C3%BCseterrine",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüseterrine"
                },
                {
                    "label": "Gemüsewähe",
                    "parameter": "passt-zu--Gem%C3%BCsew%C3%A4he",
                    "attribute": "passt-zu",
                    "attributeValue": "Gemüsewähe"
                },
                {
                    "label": "geräucherter Fisch",
                    "parameter": "passt-zu--ger%C3%A4ucherter%20Fisch",
                    "attribute": "passt-zu",
                    "attributeValue": "geräucherter Fisch"
                },
                {
                    "label": "geräucherter Lachs",
                    "parameter": "passt-zu--ger%C3%A4ucherter%20Lachs",
                    "attribute": "passt-zu",
                    "attributeValue": "geräucherter Lachs"
                },
                {
                    "label": "gereifter Käse",
                    "parameter": "passt-zu--gereifter%20K%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "gereifter Käse"
                },
                {
                    "label": "geschmorter Rindsbraten/Brasato",
                    "parameter": "passt-zu--geschmorter%20Rindsbraten%2FBrasato",
                    "attribute": "passt-zu",
                    "attributeValue": "geschmorter Rindsbraten/Brasato"
                },
                {
                    "label": "Geschnetzeltes Zürcher Art",
                    "parameter": "passt-zu--Geschnetzeltes%20Z%C3%BCrcher%20Art",
                    "attribute": "passt-zu",
                    "attributeValue": "Geschnetzeltes Zürcher Art"
                },
                {
                    "label": "Getreidegerichte",
                    "parameter": "passt-zu--Getreidegerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Getreidegerichte"
                },
                {
                    "label": "Getreiderisotto",
                    "parameter": "passt-zu--Getreiderisotto",
                    "attribute": "passt-zu",
                    "attributeValue": "Getreiderisotto"
                },
                {
                    "label": "Ghackets mit Hörnli",
                    "parameter": "passt-zu--Ghackets%20mit%20H%C3%B6rnli",
                    "attribute": "passt-zu",
                    "attributeValue": "Ghackets mit Hörnli"
                },
                {
                    "label": "Glacé",
                    "parameter": "passt-zu--Glac%C3%A9",
                    "attribute": "passt-zu",
                    "attributeValue": "Glacé"
                },
                {
                    "label": "Gnocchi",
                    "parameter": "passt-zu--Gnocchi",
                    "attribute": "passt-zu",
                    "attributeValue": "Gnocchi"
                },
                {
                    "label": "Gorgonzola",
                    "parameter": "passt-zu--Gorgonzola",
                    "attribute": "passt-zu",
                    "attributeValue": "Gorgonzola"
                },
                {
                    "label": "Greyerzer",
                    "parameter": "passt-zu--Greyerzer",
                    "attribute": "passt-zu",
                    "attributeValue": "Greyerzer"
                },
                {
                    "label": "grillierter Fisch",
                    "parameter": "passt-zu--grillierter%20Fisch",
                    "attribute": "passt-zu",
                    "attributeValue": "grillierter Fisch"
                },
                {
                    "label": "grilliertes Gemüse",
                    "parameter": "passt-zu--grilliertes%20Gem%C3%BCse",
                    "attribute": "passt-zu",
                    "attributeValue": "grilliertes Gemüse"
                },
                {
                    "label": "Gschwellti",
                    "parameter": "passt-zu--Gschwellti",
                    "attribute": "passt-zu",
                    "attributeValue": "Gschwellti"
                },
                {
                    "label": "Halbhartkäse",
                    "parameter": "passt-zu--Halbhartk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Halbhartkäse"
                },
                {
                    "label": "Hartkäse",
                    "parameter": "passt-zu--Hartk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Hartkäse"
                },
                {
                    "label": "Hase",
                    "parameter": "passt-zu--Hase",
                    "attribute": "passt-zu",
                    "attributeValue": "Hase"
                },
                {
                    "label": "Hasenragout",
                    "parameter": "passt-zu--Hasenragout",
                    "attribute": "passt-zu",
                    "attributeValue": "Hasenragout"
                },
                {
                    "label": "Hauptgericht",
                    "parameter": "passt-zu--Hauptgericht",
                    "attribute": "passt-zu",
                    "attributeValue": "Hauptgericht"
                },
                {
                    "label": "Hecht",
                    "parameter": "passt-zu--Hecht",
                    "attribute": "passt-zu",
                    "attributeValue": "Hecht"
                },
                {
                    "label": "Hirsch",
                    "parameter": "passt-zu--Hirsch",
                    "attribute": "passt-zu",
                    "attributeValue": "Hirsch"
                },
                {
                    "label": "Hirschpfeffer",
                    "parameter": "passt-zu--Hirschpfeffer",
                    "attribute": "passt-zu",
                    "attributeValue": "Hirschpfeffer"
                },
                {
                    "label": "Hirschschnitzel",
                    "parameter": "passt-zu--Hirschschnitzel",
                    "attribute": "passt-zu",
                    "attributeValue": "Hirschschnitzel"
                },
                {
                    "label": "Holzfällersteaks",
                    "parameter": "passt-zu--Holzf%C3%A4llersteaks",
                    "attribute": "passt-zu",
                    "attributeValue": "Holzfällersteaks"
                },
                {
                    "label": "Hummer",
                    "parameter": "passt-zu--Hummer",
                    "attribute": "passt-zu",
                    "attributeValue": "Hummer"
                },
                {
                    "label": "Kalb",
                    "parameter": "passt-zu--Kalb",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalb"
                },
                {
                    "label": "Kalbfleisch an Rahmsauce",
                    "parameter": "passt-zu--Kalbfleisch%20an%20Rahmsauce",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbfleisch an Rahmsauce"
                },
                {
                    "label": "Kalbsbäggli",
                    "parameter": "passt-zu--Kalbsb%C3%A4ggli",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsbäggli"
                },
                {
                    "label": "Kalbsbraten",
                    "parameter": "passt-zu--Kalbsbraten",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsbraten"
                },
                {
                    "label": "Kalbsfilet",
                    "parameter": "passt-zu--Kalbsfilet",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsfilet"
                },
                {
                    "label": "Kalbsfilet im Teig",
                    "parameter": "passt-zu--Kalbsfilet%20im%20Teig",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsfilet im Teig"
                },
                {
                    "label": "Kalbsgeschnetzeltes",
                    "parameter": "passt-zu--Kalbsgeschnetzeltes",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsgeschnetzeltes"
                },
                {
                    "label": "Kalbsgulasch",
                    "parameter": "passt-zu--Kalbsgulasch",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsgulasch"
                },
                {
                    "label": "Kalbshaxe/Ossobuco di Vitello",
                    "parameter": "passt-zu--Kalbshaxe%2FOssobuco%20di%20Vitello",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbshaxe/Ossobuco di Vitello"
                },
                {
                    "label": "Kalbskotelett",
                    "parameter": "passt-zu--Kalbskotelett",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbskotelett"
                },
                {
                    "label": "Kalbsleber",
                    "parameter": "passt-zu--Kalbsleber",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsleber"
                },
                {
                    "label": "Kalbsragout/Kalbsvoressen",
                    "parameter": "passt-zu--Kalbsragout%2FKalbsvoressen",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsragout/Kalbsvoressen"
                },
                {
                    "label": "Kalbsschnitzel an Rahmsauce",
                    "parameter": "passt-zu--Kalbsschnitzel%20an%20Rahmsauce",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbsschnitzel an Rahmsauce"
                },
                {
                    "label": "Kalbssteak",
                    "parameter": "passt-zu--Kalbssteak",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalbssteak"
                },
                {
                    "label": "Kalte Platte",
                    "parameter": "passt-zu--Kalte%20Platte",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalte Platte"
                },
                {
                    "label": "Kalte Suppen",
                    "parameter": "passt-zu--Kalte%20Suppen",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalte Suppen"
                },
                {
                    "label": "Kalte Süssspeisen",
                    "parameter": "passt-zu--Kalte%20S%C3%BCssspeisen",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalte Süssspeisen"
                },
                {
                    "label": "Kalte Vorspeise",
                    "parameter": "passt-zu--Kalte%20Vorspeise",
                    "attribute": "passt-zu",
                    "attributeValue": "Kalte Vorspeise"
                },
                {
                    "label": "Kaninchen",
                    "parameter": "passt-zu--Kaninchen",
                    "attribute": "passt-zu",
                    "attributeValue": "Kaninchen"
                },
                {
                    "label": "Kaninchenragout",
                    "parameter": "passt-zu--Kaninchenragout",
                    "attribute": "passt-zu",
                    "attributeValue": "Kaninchenragout"
                },
                {
                    "label": "Kartoffelgerichte",
                    "parameter": "passt-zu--Kartoffelgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Kartoffelgerichte"
                },
                {
                    "label": "Kartoffelgratin",
                    "parameter": "passt-zu--Kartoffelgratin",
                    "attribute": "passt-zu",
                    "attributeValue": "Kartoffelgratin"
                },
                {
                    "label": "Kartoffelstock",
                    "parameter": "passt-zu--Kartoffelstock",
                    "attribute": "passt-zu",
                    "attributeValue": "Kartoffelstock"
                },
                {
                    "label": "Käse",
                    "parameter": "passt-zu--K%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Käse"
                },
                {
                    "label": "Käse-Soufflé",
                    "parameter": "passt-zu--K%C3%A4se-Souffl%C3%A9",
                    "attribute": "passt-zu",
                    "attributeValue": "Käse-Soufflé"
                },
                {
                    "label": "Käseplatte",
                    "parameter": "passt-zu--K%C3%A4seplatte",
                    "attribute": "passt-zu",
                    "attributeValue": "Käseplatte"
                },
                {
                    "label": "Kaviar",
                    "parameter": "passt-zu--Kaviar",
                    "attribute": "passt-zu",
                    "attributeValue": "Kaviar"
                },
                {
                    "label": "Kleingebäck und Kuchen",
                    "parameter": "passt-zu--Kleingeb%C3%A4ck%20und%20Kuchen",
                    "attribute": "passt-zu",
                    "attributeValue": "Kleingebäck und Kuchen"
                },
                {
                    "label": "Kräuteromelette",
                    "parameter": "passt-zu--Kr%C3%A4uteromelette",
                    "attribute": "passt-zu",
                    "attributeValue": "Kräuteromelette"
                },
                {
                    "label": "Krustentiere",
                    "parameter": "passt-zu--Krustentiere",
                    "attribute": "passt-zu",
                    "attributeValue": "Krustentiere"
                },
                {
                    "label": "Kuchen",
                    "parameter": "passt-zu--Kuchen",
                    "attribute": "passt-zu",
                    "attributeValue": "Kuchen"
                },
                {
                    "label": "Lachs",
                    "parameter": "passt-zu--Lachs",
                    "attribute": "passt-zu",
                    "attributeValue": "Lachs"
                },
                {
                    "label": "Lachs-Tatar",
                    "parameter": "passt-zu--Lachs-Tatar",
                    "attribute": "passt-zu",
                    "attributeValue": "Lachs-Tatar"
                },
                {
                    "label": "Lachsmousse",
                    "parameter": "passt-zu--Lachsmousse",
                    "attribute": "passt-zu",
                    "attributeValue": "Lachsmousse"
                },
                {
                    "label": "Lamm",
                    "parameter": "passt-zu--Lamm",
                    "attribute": "passt-zu",
                    "attributeValue": "Lamm"
                },
                {
                    "label": "Lamm-Grilladen",
                    "parameter": "passt-zu--Lamm-Grilladen",
                    "attribute": "passt-zu",
                    "attributeValue": "Lamm-Grilladen"
                },
                {
                    "label": "Lamm-Tagine",
                    "parameter": "passt-zu--Lamm-Tagine",
                    "attribute": "passt-zu",
                    "attributeValue": "Lamm-Tagine"
                },
                {
                    "label": "Lammcarré/Lammrack",
                    "parameter": "passt-zu--Lammcarr%C3%A9%2FLammrack",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammcarré/Lammrack"
                },
                {
                    "label": "Lammfilet",
                    "parameter": "passt-zu--Lammfilet",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammfilet"
                },
                {
                    "label": "Lammgigot an Kräutermarinade",
                    "parameter": "passt-zu--Lammgigot%20an%20Kr%C3%A4utermarinade",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammgigot an Kräutermarinade"
                },
                {
                    "label": "Lammgigot/Keule",
                    "parameter": "passt-zu--Lammgigot%2FKeule",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammgigot/Keule"
                },
                {
                    "label": "Lammkotelett/Lammchops",
                    "parameter": "passt-zu--Lammkotelett%2FLammchops",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammkotelett/Lammchops"
                },
                {
                    "label": "Lammragout/Lammvoressen",
                    "parameter": "passt-zu--Lammragout%2FLammvoressen",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammragout/Lammvoressen"
                },
                {
                    "label": "Lammspiess",
                    "parameter": "passt-zu--Lammspiess",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammspiess"
                },
                {
                    "label": "Lammsteak",
                    "parameter": "passt-zu--Lammsteak",
                    "attribute": "passt-zu",
                    "attributeValue": "Lammsteak"
                },
                {
                    "label": "Languste",
                    "parameter": "passt-zu--Languste",
                    "attribute": "passt-zu",
                    "attributeValue": "Languste"
                },
                {
                    "label": "Lardo",
                    "parameter": "passt-zu--Lardo",
                    "attribute": "passt-zu",
                    "attributeValue": "Lardo"
                },
                {
                    "label": "Lasagne",
                    "parameter": "passt-zu--Lasagne",
                    "attribute": "passt-zu",
                    "attributeValue": "Lasagne"
                },
                {
                    "label": "lateinamerikanische Spezialitäten",
                    "parameter": "passt-zu--lateinamerikanische%20Spezialit%C3%A4ten",
                    "attribute": "passt-zu",
                    "attributeValue": "lateinamerikanische Spezialitäten"
                },
                {
                    "label": "Linsengerichte",
                    "parameter": "passt-zu--Linsengerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Linsengerichte"
                },
                {
                    "label": "Loup de mer",
                    "parameter": "passt-zu--Loup%20de%20mer",
                    "attribute": "passt-zu",
                    "attributeValue": "Loup de mer"
                },
                {
                    "label": "Mais",
                    "parameter": "passt-zu--Mais",
                    "attribute": "passt-zu",
                    "attributeValue": "Mais"
                },
                {
                    "label": "Manchego",
                    "parameter": "passt-zu--Manchego",
                    "attribute": "passt-zu",
                    "attributeValue": "Manchego"
                },
                {
                    "label": "Mandelcreme",
                    "parameter": "passt-zu--Mandelcreme",
                    "attribute": "passt-zu",
                    "attributeValue": "Mandelcreme"
                },
                {
                    "label": "Mediterrane Küche",
                    "parameter": "passt-zu--Mediterrane%20K%C3%BCche",
                    "attribute": "passt-zu",
                    "attributeValue": "Mediterrane Küche"
                },
                {
                    "label": "Meeresfisch",
                    "parameter": "passt-zu--Meeresfisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Meeresfisch"
                },
                {
                    "label": "Meeresfrüchte",
                    "parameter": "passt-zu--Meeresfr%C3%BCchte",
                    "attribute": "passt-zu",
                    "attributeValue": "Meeresfrüchte"
                },
                {
                    "label": "Melonen mit Rohschinken",
                    "parameter": "passt-zu--Melonen%20mit%20Rohschinken",
                    "attribute": "passt-zu",
                    "attributeValue": "Melonen mit Rohschinken"
                },
                {
                    "label": "Meringues",
                    "parameter": "passt-zu--Meringues",
                    "attribute": "passt-zu",
                    "attributeValue": "Meringues"
                },
                {
                    "label": "Mortadella",
                    "parameter": "passt-zu--Mortadella",
                    "attribute": "passt-zu",
                    "attributeValue": "Mortadella"
                },
                {
                    "label": "Muscheln",
                    "parameter": "passt-zu--Muscheln",
                    "attribute": "passt-zu",
                    "attributeValue": "Muscheln"
                },
                {
                    "label": "Nachtisch",
                    "parameter": "passt-zu--Nachtisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Nachtisch"
                },
                {
                    "label": "Nationalsuppen",
                    "parameter": "passt-zu--Nationalsuppen",
                    "attribute": "passt-zu",
                    "attributeValue": "Nationalsuppen"
                },
                {
                    "label": "nordafrikanische Küche",
                    "parameter": "passt-zu--nordafrikanische%20K%C3%BCche",
                    "attribute": "passt-zu",
                    "attributeValue": "nordafrikanische Küche"
                },
                {
                    "label": "Nüsse/Nussfrüchte",
                    "parameter": "passt-zu--N%C3%BCsse%2FNussfr%C3%BCchte",
                    "attribute": "passt-zu",
                    "attributeValue": "Nüsse/Nussfrüchte"
                },
                {
                    "label": "Orientalische Küche",
                    "parameter": "passt-zu--Orientalische%20K%C3%BCche",
                    "attribute": "passt-zu",
                    "attributeValue": "Orientalische Küche"
                },
                {
                    "label": "Paella",
                    "parameter": "passt-zu--Paella",
                    "attribute": "passt-zu",
                    "attributeValue": "Paella"
                },
                {
                    "label": "Panna Cotta",
                    "parameter": "passt-zu--Panna%20Cotta",
                    "attribute": "passt-zu",
                    "attributeValue": "Panna Cotta"
                },
                {
                    "label": "Parmesan",
                    "parameter": "passt-zu--Parmesan",
                    "attribute": "passt-zu",
                    "attributeValue": "Parmesan"
                },
                {
                    "label": "Pasta/Teigwaren",
                    "parameter": "passt-zu--Pasta%2FTeigwaren",
                    "attribute": "passt-zu",
                    "attributeValue": "Pasta/Teigwaren"
                },
                {
                    "label": "Pasteten/Terrinen",
                    "parameter": "passt-zu--Pasteten%2FTerrinen",
                    "attribute": "passt-zu",
                    "attributeValue": "Pasteten/Terrinen"
                },
                {
                    "label": "Pâtisserie",
                    "parameter": "passt-zu--P%C3%A2tisserie",
                    "attribute": "passt-zu",
                    "attributeValue": "Pâtisserie"
                },
                {
                    "label": "Pecorino",
                    "parameter": "passt-zu--Pecorino",
                    "attribute": "passt-zu",
                    "attributeValue": "Pecorino"
                },
                {
                    "label": "Penne",
                    "parameter": "passt-zu--Penne",
                    "attribute": "passt-zu",
                    "attributeValue": "Penne"
                },
                {
                    "label": "Penne al tonno",
                    "parameter": "passt-zu--Penne%20al%20tonno",
                    "attribute": "passt-zu",
                    "attributeValue": "Penne al tonno"
                },
                {
                    "label": "Penne alla panna",
                    "parameter": "passt-zu--Penne%20alla%20panna",
                    "attribute": "passt-zu",
                    "attributeValue": "Penne alla panna"
                },
                {
                    "label": "Perlhuhn",
                    "parameter": "passt-zu--Perlhuhn",
                    "attribute": "passt-zu",
                    "attributeValue": "Perlhuhn"
                },
                {
                    "label": "Piccata Milanese",
                    "parameter": "passt-zu--Piccata%20Milanese",
                    "attribute": "passt-zu",
                    "attributeValue": "Piccata Milanese"
                },
                {
                    "label": "pikante Brioche",
                    "parameter": "passt-zu--pikante%20Brioche",
                    "attribute": "passt-zu",
                    "attributeValue": "pikante Brioche"
                },
                {
                    "label": "pikante Wähe",
                    "parameter": "passt-zu--pikante%20W%C3%A4he",
                    "attribute": "passt-zu",
                    "attributeValue": "pikante Wähe"
                },
                {
                    "label": "pikantes Gebäck",
                    "parameter": "passt-zu--pikantes%20Geb%C3%A4ck",
                    "attribute": "passt-zu",
                    "attributeValue": "pikantes Gebäck"
                },
                {
                    "label": "Pilzragout",
                    "parameter": "passt-zu--Pilzragout",
                    "attribute": "passt-zu",
                    "attributeValue": "Pilzragout"
                },
                {
                    "label": "Pilzrisotto",
                    "parameter": "passt-zu--Pilzrisotto",
                    "attribute": "passt-zu",
                    "attributeValue": "Pilzrisotto"
                },
                {
                    "label": "Pilzsuppe",
                    "parameter": "passt-zu--Pilzsuppe",
                    "attribute": "passt-zu",
                    "attributeValue": "Pilzsuppe"
                },
                {
                    "label": "Pilzvorspeisen",
                    "parameter": "passt-zu--Pilzvorspeisen",
                    "attribute": "passt-zu",
                    "attributeValue": "Pilzvorspeisen"
                },
                {
                    "label": "Pinchos",
                    "parameter": "passt-zu--Pinchos",
                    "attribute": "passt-zu",
                    "attributeValue": "Pinchos"
                },
                {
                    "label": "Pizza",
                    "parameter": "passt-zu--Pizza",
                    "attribute": "passt-zu",
                    "attributeValue": "Pizza"
                },
                {
                    "label": "pochierter Fisch",
                    "parameter": "passt-zu--pochierter%20Fisch",
                    "attribute": "passt-zu",
                    "attributeValue": "pochierter Fisch"
                },
                {
                    "label": "pochiertes Ei",
                    "parameter": "passt-zu--pochiertes%20Ei",
                    "attribute": "passt-zu",
                    "attributeValue": "pochiertes Ei"
                },
                {
                    "label": "Polenta",
                    "parameter": "passt-zu--Polenta",
                    "attribute": "passt-zu",
                    "attributeValue": "Polenta"
                },
                {
                    "label": "Pollo asado/Brathuhn",
                    "parameter": "passt-zu--Pollo%20asado%2FBrathuhn",
                    "attribute": "passt-zu",
                    "attributeValue": "Pollo asado/Brathuhn"
                },
                {
                    "label": "Porterhouse-Steak",
                    "parameter": "passt-zu--Porterhouse-Steak",
                    "attribute": "passt-zu",
                    "attributeValue": "Porterhouse-Steak"
                },
                {
                    "label": "Pot-au-feu",
                    "parameter": "passt-zu--Pot-au-feu",
                    "attribute": "passt-zu",
                    "attributeValue": "Pot-au-feu"
                },
                {
                    "label": "Poularde",
                    "parameter": "passt-zu--Poularde",
                    "attribute": "passt-zu",
                    "attributeValue": "Poularde"
                },
                {
                    "label": "Poulet",
                    "parameter": "passt-zu--Poulet",
                    "attribute": "passt-zu",
                    "attributeValue": "Poulet"
                },
                {
                    "label": "Poulet an Weissweinsauce",
                    "parameter": "passt-zu--Poulet%20an%20Weissweinsauce",
                    "attribute": "passt-zu",
                    "attributeValue": "Poulet an Weissweinsauce"
                },
                {
                    "label": "Poulet vom Grill",
                    "parameter": "passt-zu--Poulet%20vom%20Grill",
                    "attribute": "passt-zu",
                    "attributeValue": "Poulet vom Grill"
                },
                {
                    "label": "Poulet-Tagine",
                    "parameter": "passt-zu--Poulet-Tagine",
                    "attribute": "passt-zu",
                    "attributeValue": "Poulet-Tagine"
                },
                {
                    "label": "Pouletbrust",
                    "parameter": "passt-zu--Pouletbrust",
                    "attribute": "passt-zu",
                    "attributeValue": "Pouletbrust"
                },
                {
                    "label": "Pouletgeschnetzeltes",
                    "parameter": "passt-zu--Pouletgeschnetzeltes",
                    "attribute": "passt-zu",
                    "attributeValue": "Pouletgeschnetzeltes"
                },
                {
                    "label": "Pouletschenkel",
                    "parameter": "passt-zu--Pouletschenkel",
                    "attribute": "passt-zu",
                    "attributeValue": "Pouletschenkel"
                },
                {
                    "label": "Pudding",
                    "parameter": "passt-zu--Pudding",
                    "attribute": "passt-zu",
                    "attributeValue": "Pudding"
                },
                {
                    "label": "Quiche lorraine",
                    "parameter": "passt-zu--Quiche%20lorraine",
                    "attribute": "passt-zu",
                    "attributeValue": "Quiche lorraine"
                },
                {
                    "label": "Raclette",
                    "parameter": "passt-zu--Raclette",
                    "attribute": "passt-zu",
                    "attributeValue": "Raclette"
                },
                {
                    "label": "Ratatouille",
                    "parameter": "passt-zu--Ratatouille",
                    "attribute": "passt-zu",
                    "attributeValue": "Ratatouille"
                },
                {
                    "label": "Ravioli",
                    "parameter": "passt-zu--Ravioli",
                    "attribute": "passt-zu",
                    "attributeValue": "Ravioli"
                },
                {
                    "label": "Ravioli an Tomatensauce",
                    "parameter": "passt-zu--Ravioli%20an%20Tomatensauce",
                    "attribute": "passt-zu",
                    "attributeValue": "Ravioli an Tomatensauce"
                },
                {
                    "label": "Ravioli mit Kräuterfüllung",
                    "parameter": "passt-zu--Ravioli%20mit%20Kr%C3%A4uterf%C3%BCllung",
                    "attribute": "passt-zu",
                    "attributeValue": "Ravioli mit Kräuterfüllung"
                },
                {
                    "label": "Ravioli mit Ricottafüllung",
                    "parameter": "passt-zu--Ravioli%20mit%20Ricottaf%C3%BCllung",
                    "attribute": "passt-zu",
                    "attributeValue": "Ravioli mit Ricottafüllung"
                },
                {
                    "label": "Rebhuhn",
                    "parameter": "passt-zu--Rebhuhn",
                    "attribute": "passt-zu",
                    "attributeValue": "Rebhuhn"
                },
                {
                    "label": "Reh",
                    "parameter": "passt-zu--Reh",
                    "attribute": "passt-zu",
                    "attributeValue": "Reh"
                },
                {
                    "label": "Rehpfeffer",
                    "parameter": "passt-zu--Rehpfeffer",
                    "attribute": "passt-zu",
                    "attributeValue": "Rehpfeffer"
                },
                {
                    "label": "Rehrücken",
                    "parameter": "passt-zu--Rehr%C3%BCcken",
                    "attribute": "passt-zu",
                    "attributeValue": "Rehrücken"
                },
                {
                    "label": "Rehschnitzel",
                    "parameter": "passt-zu--Rehschnitzel",
                    "attribute": "passt-zu",
                    "attributeValue": "Rehschnitzel"
                },
                {
                    "label": "Reissalat",
                    "parameter": "passt-zu--Reissalat",
                    "attribute": "passt-zu",
                    "attributeValue": "Reissalat"
                },
                {
                    "label": "rezente/würzige Käsesorten",
                    "parameter": "passt-zu--rezente%2Fw%C3%BCrzige%20K%C3%A4sesorten",
                    "attribute": "passt-zu",
                    "attributeValue": "rezente/würzige Käsesorten"
                },
                {
                    "label": "Rib-Eye-Steak",
                    "parameter": "passt-zu--Rib-Eye-Steak",
                    "attribute": "passt-zu",
                    "attributeValue": "Rib-Eye-Steak"
                },
                {
                    "label": "Rind",
                    "parameter": "passt-zu--Rind",
                    "attribute": "passt-zu",
                    "attributeValue": "Rind"
                },
                {
                    "label": "Rinds-Grilladen",
                    "parameter": "passt-zu--Rinds-Grilladen",
                    "attribute": "passt-zu",
                    "attributeValue": "Rinds-Grilladen"
                },
                {
                    "label": "Rindsbraten",
                    "parameter": "passt-zu--Rindsbraten",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsbraten"
                },
                {
                    "label": "Rindsentrecôte",
                    "parameter": "passt-zu--Rindsentrec%C3%B4te",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsentrecôte"
                },
                {
                    "label": "Rindsfilet",
                    "parameter": "passt-zu--Rindsfilet",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsfilet"
                },
                {
                    "label": "Rindsfilet im Teig/Filet Wellington",
                    "parameter": "passt-zu--Rindsfilet%20im%20Teig%2FFilet%20Wellington",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsfilet im Teig/Filet Wellington"
                },
                {
                    "label": "Rindsfilet Stroganoff",
                    "parameter": "passt-zu--Rindsfilet%20Stroganoff",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsfilet Stroganoff"
                },
                {
                    "label": "Rindsgeschnetzeltes",
                    "parameter": "passt-zu--Rindsgeschnetzeltes",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsgeschnetzeltes"
                },
                {
                    "label": "Rindsgulasch",
                    "parameter": "passt-zu--Rindsgulasch",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsgulasch"
                },
                {
                    "label": "Rindshaxe/Ossobuco di manzo",
                    "parameter": "passt-zu--Rindshaxe%2FOssobuco%20di%20manzo",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindshaxe/Ossobuco di manzo"
                },
                {
                    "label": "Rindsragout/Rindsvoressen",
                    "parameter": "passt-zu--Rindsragout%2FRindsvoressen",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsragout/Rindsvoressen"
                },
                {
                    "label": "Rindssiedfleisch",
                    "parameter": "passt-zu--Rindssiedfleisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindssiedfleisch"
                },
                {
                    "label": "Rindsspiess",
                    "parameter": "passt-zu--Rindsspiess",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindsspiess"
                },
                {
                    "label": "Rindssteak",
                    "parameter": "passt-zu--Rindssteak",
                    "attribute": "passt-zu",
                    "attributeValue": "Rindssteak"
                },
                {
                    "label": "Risotto",
                    "parameter": "passt-zu--Risotto",
                    "attribute": "passt-zu",
                    "attributeValue": "Risotto"
                },
                {
                    "label": "Roastbeef",
                    "parameter": "passt-zu--Roastbeef",
                    "attribute": "passt-zu",
                    "attributeValue": "Roastbeef"
                },
                {
                    "label": "Rohschinken",
                    "parameter": "passt-zu--Rohschinken",
                    "attribute": "passt-zu",
                    "attributeValue": "Rohschinken"
                },
                {
                    "label": "Rollschinkli im Brotteig",
                    "parameter": "passt-zu--Rollschinkli%20im%20Brotteig",
                    "attribute": "passt-zu",
                    "attributeValue": "Rollschinkli im Brotteig"
                },
                {
                    "label": "Roquefort",
                    "parameter": "passt-zu--Roquefort",
                    "attribute": "passt-zu",
                    "attributeValue": "Roquefort"
                },
                {
                    "label": "Rösti",
                    "parameter": "passt-zu--R%C3%B6sti",
                    "attribute": "passt-zu",
                    "attributeValue": "Rösti"
                },
                {
                    "label": "Rotkraut",
                    "parameter": "passt-zu--Rotkraut",
                    "attribute": "passt-zu",
                    "attributeValue": "Rotkraut"
                },
                {
                    "label": "Rouget",
                    "parameter": "passt-zu--Rouget",
                    "attribute": "passt-zu",
                    "attributeValue": "Rouget"
                },
                {
                    "label": "Safranrisotto",
                    "parameter": "passt-zu--Safranrisotto",
                    "attribute": "passt-zu",
                    "attributeValue": "Safranrisotto"
                },
                {
                    "label": "Salade niçoise",
                    "parameter": "passt-zu--Salade%20ni%C3%A7oise",
                    "attribute": "passt-zu",
                    "attributeValue": "Salade niçoise"
                },
                {
                    "label": "Salami",
                    "parameter": "passt-zu--Salami",
                    "attribute": "passt-zu",
                    "attributeValue": "Salami"
                },
                {
                    "label": "Salat",
                    "parameter": "passt-zu--Salat",
                    "attribute": "passt-zu",
                    "attributeValue": "Salat"
                },
                {
                    "label": "Salzgebäck",
                    "parameter": "passt-zu--Salzgeb%C3%A4ck",
                    "attribute": "passt-zu",
                    "attributeValue": "Salzgebäck"
                },
                {
                    "label": "Saucisson",
                    "parameter": "passt-zu--Saucisson",
                    "attribute": "passt-zu",
                    "attributeValue": "Saucisson"
                },
                {
                    "label": "Sbrinz",
                    "parameter": "passt-zu--Sbrinz",
                    "attribute": "passt-zu",
                    "attributeValue": "Sbrinz"
                },
                {
                    "label": "Schafskäse",
                    "parameter": "passt-zu--Schafsk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Schafskäse"
                },
                {
                    "label": "Schinkengipfel",
                    "parameter": "passt-zu--Schinkengipfel",
                    "attribute": "passt-zu",
                    "attributeValue": "Schinkengipfel"
                },
                {
                    "label": "Schokolade",
                    "parameter": "passt-zu--Schokolade",
                    "attribute": "passt-zu",
                    "attributeValue": "Schokolade"
                },
                {
                    "label": "Schokoladenkuchen",
                    "parameter": "passt-zu--Schokoladenkuchen",
                    "attribute": "passt-zu",
                    "attributeValue": "Schokoladenkuchen"
                },
                {
                    "label": "Schokoladenmousse",
                    "parameter": "passt-zu--Schokoladenmousse",
                    "attribute": "passt-zu",
                    "attributeValue": "Schokoladenmousse"
                },
                {
                    "label": "schwarze Schokolade/Bitterschokolade",
                    "parameter": "passt-zu--schwarze%20Schokolade%2FBitterschokolade",
                    "attribute": "passt-zu",
                    "attributeValue": "schwarze Schokolade/Bitterschokolade"
                },
                {
                    "label": "Schwein",
                    "parameter": "passt-zu--Schwein",
                    "attribute": "passt-zu",
                    "attributeValue": "Schwein"
                },
                {
                    "label": "Schweins-Grilladen",
                    "parameter": "passt-zu--Schweins-Grilladen",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweins-Grilladen"
                },
                {
                    "label": "Schweinsbraten",
                    "parameter": "passt-zu--Schweinsbraten",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinsbraten"
                },
                {
                    "label": "Schweinsbratwurst/Bauernbratwurst",
                    "parameter": "passt-zu--Schweinsbratwurst%2FBauernbratwurst",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinsbratwurst/Bauernbratwurst"
                },
                {
                    "label": "Schweinsfilet",
                    "parameter": "passt-zu--Schweinsfilet",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinsfilet"
                },
                {
                    "label": "Schweinsfilet im Teig",
                    "parameter": "passt-zu--Schweinsfilet%20im%20Teig",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinsfilet im Teig"
                },
                {
                    "label": "Schweinshackbraten",
                    "parameter": "passt-zu--Schweinshackbraten",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinshackbraten"
                },
                {
                    "label": "Schweinsragout/Schweinsvoressen",
                    "parameter": "passt-zu--Schweinsragout%2FSchweinsvoressen",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinsragout/Schweinsvoressen"
                },
                {
                    "label": "Schweinsspiess",
                    "parameter": "passt-zu--Schweinsspiess",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinsspiess"
                },
                {
                    "label": "Schweinssteak",
                    "parameter": "passt-zu--Schweinssteak",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweinssteak"
                },
                {
                    "label": "Schweizer Küche",
                    "parameter": "passt-zu--Schweizer%20K%C3%BCche",
                    "attribute": "passt-zu",
                    "attributeValue": "Schweizer Küche"
                },
                {
                    "label": "Spaghetti",
                    "parameter": "passt-zu--Spaghetti",
                    "attribute": "passt-zu",
                    "attributeValue": "Spaghetti"
                },
                {
                    "label": "Spaghetti alle Vongole",
                    "parameter": "passt-zu--Spaghetti%20alle%20Vongole",
                    "attribute": "passt-zu",
                    "attributeValue": "Spaghetti alle Vongole"
                },
                {
                    "label": "Spaghetti an Tomatensauce",
                    "parameter": "passt-zu--Spaghetti%20an%20Tomatensauce",
                    "attribute": "passt-zu",
                    "attributeValue": "Spaghetti an Tomatensauce"
                },
                {
                    "label": "Spaghetti Bolognese",
                    "parameter": "passt-zu--Spaghetti%20Bolognese",
                    "attribute": "passt-zu",
                    "attributeValue": "Spaghetti Bolognese"
                },
                {
                    "label": "Spare-Ribs",
                    "parameter": "passt-zu--Spare-Ribs",
                    "attribute": "passt-zu",
                    "attributeValue": "Spare-Ribs"
                },
                {
                    "label": "Spargeln",
                    "parameter": "passt-zu--Spargeln",
                    "attribute": "passt-zu",
                    "attributeValue": "Spargeln"
                },
                {
                    "label": "Spätzli / Chnöpfli",
                    "parameter": "passt-zu--Sp%C3%A4tzli%20%2F%20Chn%C3%B6pfli",
                    "attribute": "passt-zu",
                    "attributeValue": "Spätzli / Chnöpfli"
                },
                {
                    "label": "Speck",
                    "parameter": "passt-zu--Speck",
                    "attribute": "passt-zu",
                    "attributeValue": "Speck"
                },
                {
                    "label": "Spinat-Soufflé",
                    "parameter": "passt-zu--Spinat-Souffl%C3%A9",
                    "attribute": "passt-zu",
                    "attributeValue": "Spinat-Soufflé"
                },
                {
                    "label": "Steinpilze",
                    "parameter": "passt-zu--Steinpilze",
                    "attribute": "passt-zu",
                    "attributeValue": "Steinpilze"
                },
                {
                    "label": "Stilton",
                    "parameter": "passt-zu--Stilton",
                    "attribute": "passt-zu",
                    "attributeValue": "Stilton"
                },
                {
                    "label": "Suppe",
                    "parameter": "passt-zu--Suppe",
                    "attribute": "passt-zu",
                    "attributeValue": "Suppe"
                },
                {
                    "label": "Sushi",
                    "parameter": "passt-zu--Sushi",
                    "attribute": "passt-zu",
                    "attributeValue": "Sushi"
                },
                {
                    "label": "Süsswasserfisch",
                    "parameter": "passt-zu--S%C3%BCsswasserfisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Süsswasserfisch"
                },
                {
                    "label": "Sweet and sour",
                    "parameter": "passt-zu--Sweet%20and%20sour",
                    "attribute": "passt-zu",
                    "attributeValue": "Sweet and sour"
                },
                {
                    "label": "T-Bone-Steak",
                    "parameter": "passt-zu--T-Bone-Steak",
                    "attribute": "passt-zu",
                    "attributeValue": "T-Bone-Steak"
                },
                {
                    "label": "Tafelspitz",
                    "parameter": "passt-zu--Tafelspitz",
                    "attribute": "passt-zu",
                    "attributeValue": "Tafelspitz"
                },
                {
                    "label": "Tapas",
                    "parameter": "passt-zu--Tapas",
                    "attribute": "passt-zu",
                    "attributeValue": "Tapas"
                },
                {
                    "label": "Tapas mit Chips",
                    "parameter": "passt-zu--Tapas%20mit%20Chips",
                    "attribute": "passt-zu",
                    "attributeValue": "Tapas mit Chips"
                },
                {
                    "label": "Tatar",
                    "parameter": "passt-zu--Tatar",
                    "attribute": "passt-zu",
                    "attributeValue": "Tatar"
                },
                {
                    "label": "Teigvorspeisen",
                    "parameter": "passt-zu--Teigvorspeisen",
                    "attribute": "passt-zu",
                    "attributeValue": "Teigvorspeisen"
                },
                {
                    "label": "Teigwarenauflauf",
                    "parameter": "passt-zu--Teigwarenauflauf",
                    "attribute": "passt-zu",
                    "attributeValue": "Teigwarenauflauf"
                },
                {
                    "label": "Tex-Mex-Küche",
                    "parameter": "passt-zu--Tex-Mex-K%C3%BCche",
                    "attribute": "passt-zu",
                    "attributeValue": "Tex-Mex-Küche"
                },
                {
                    "label": "Thaï",
                    "parameter": "passt-zu--Tha%C3%AF",
                    "attribute": "passt-zu",
                    "attributeValue": "Thaï"
                },
                {
                    "label": "Thunfisch",
                    "parameter": "passt-zu--Thunfisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Thunfisch"
                },
                {
                    "label": "Tilsiter",
                    "parameter": "passt-zu--Tilsiter",
                    "attribute": "passt-zu",
                    "attributeValue": "Tilsiter"
                },
                {
                    "label": "Tintenfisch",
                    "parameter": "passt-zu--Tintenfisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Tintenfisch"
                },
                {
                    "label": "Tomatenrisotto",
                    "parameter": "passt-zu--Tomatenrisotto",
                    "attribute": "passt-zu",
                    "attributeValue": "Tomatenrisotto"
                },
                {
                    "label": "Tomme",
                    "parameter": "passt-zu--Tomme",
                    "attribute": "passt-zu",
                    "attributeValue": "Tomme"
                },
                {
                    "label": "Torten",
                    "parameter": "passt-zu--Torten",
                    "attribute": "passt-zu",
                    "attributeValue": "Torten"
                },
                {
                    "label": "Tortilla Española",
                    "parameter": "passt-zu--Tortilla%20Espa%C3%B1ola",
                    "attribute": "passt-zu",
                    "attributeValue": "Tortilla Española"
                },
                {
                    "label": "Tournedos",
                    "parameter": "passt-zu--Tournedos",
                    "attribute": "passt-zu",
                    "attributeValue": "Tournedos"
                },
                {
                    "label": "Trockenfleisch",
                    "parameter": "passt-zu--Trockenfleisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Trockenfleisch"
                },
                {
                    "label": "Truthahn",
                    "parameter": "passt-zu--Truthahn",
                    "attribute": "passt-zu",
                    "attributeValue": "Truthahn"
                },
                {
                    "label": "Vanillecreme",
                    "parameter": "passt-zu--Vanillecreme",
                    "attribute": "passt-zu",
                    "attributeValue": "Vanillecreme"
                },
                {
                    "label": "Vegetarische Gerichte",
                    "parameter": "passt-zu--Vegetarische%20Gerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Vegetarische Gerichte"
                },
                {
                    "label": "Vesperplatte",
                    "parameter": "passt-zu--Vesperplatte",
                    "attribute": "passt-zu",
                    "attributeValue": "Vesperplatte"
                },
                {
                    "label": "Vitello tonnato",
                    "parameter": "passt-zu--Vitello%20tonnato",
                    "attribute": "passt-zu",
                    "attributeValue": "Vitello tonnato"
                },
                {
                    "label": "Vorspeisen mit Fisch",
                    "parameter": "passt-zu--Vorspeisen%20mit%20Fisch",
                    "attribute": "passt-zu",
                    "attributeValue": "Vorspeisen mit Fisch"
                },
                {
                    "label": "Vorspeisencocktails",
                    "parameter": "passt-zu--Vorspeisencocktails",
                    "attribute": "passt-zu",
                    "attributeValue": "Vorspeisencocktails"
                },
                {
                    "label": "Wachtel",
                    "parameter": "passt-zu--Wachtel",
                    "attribute": "passt-zu",
                    "attributeValue": "Wachtel"
                },
                {
                    "label": "Wähen/Quiche",
                    "parameter": "passt-zu--W%C3%A4hen%2FQuiche",
                    "attribute": "passt-zu",
                    "attributeValue": "Wähen/Quiche"
                },
                {
                    "label": "Walliser Spezialitäten",
                    "parameter": "passt-zu--Walliser%20Spezialit%C3%A4ten",
                    "attribute": "passt-zu",
                    "attributeValue": "Walliser Spezialitäten"
                },
                {
                    "label": "Warme Süssspeisen",
                    "parameter": "passt-zu--Warme%20S%C3%BCssspeisen",
                    "attribute": "passt-zu",
                    "attributeValue": "Warme Süssspeisen"
                },
                {
                    "label": "Warme Vorspeise",
                    "parameter": "passt-zu--Warme%20Vorspeise",
                    "attribute": "passt-zu",
                    "attributeValue": "Warme Vorspeise"
                },
                {
                    "label": "Wasabi",
                    "parameter": "passt-zu--Wasabi",
                    "attribute": "passt-zu",
                    "attributeValue": "Wasabi"
                },
                {
                    "label": "Weichkäse",
                    "parameter": "passt-zu--Weichk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Weichkäse"
                },
                {
                    "label": "Weichtiere",
                    "parameter": "passt-zu--Weichtiere",
                    "attribute": "passt-zu",
                    "attributeValue": "Weichtiere"
                },
                {
                    "label": "Weissschimmelkäse",
                    "parameter": "passt-zu--Weissschimmelk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Weissschimmelkäse"
                },
                {
                    "label": "Wiener Schnitzel",
                    "parameter": "passt-zu--Wiener%20Schnitzel",
                    "attribute": "passt-zu",
                    "attributeValue": "Wiener Schnitzel"
                },
                {
                    "label": "Wild",
                    "parameter": "passt-zu--Wild",
                    "attribute": "passt-zu",
                    "attributeValue": "Wild"
                },
                {
                    "label": "Wildragout",
                    "parameter": "passt-zu--Wildragout",
                    "attribute": "passt-zu",
                    "attributeValue": "Wildragout"
                },
                {
                    "label": "Wildschmorgerichte",
                    "parameter": "passt-zu--Wildschmorgerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Wildschmorgerichte"
                },
                {
                    "label": "Wildschwein",
                    "parameter": "passt-zu--Wildschwein",
                    "attribute": "passt-zu",
                    "attributeValue": "Wildschwein"
                },
                {
                    "label": "Wok-Gerichte",
                    "parameter": "passt-zu--Wok-Gerichte",
                    "attribute": "passt-zu",
                    "attributeValue": "Wok-Gerichte"
                },
                {
                    "label": "Würste vom Grill",
                    "parameter": "passt-zu--W%C3%BCrste%20vom%20Grill",
                    "attribute": "passt-zu",
                    "attributeValue": "Würste vom Grill"
                },
                {
                    "label": "Ziegenfrischkäse",
                    "parameter": "passt-zu--Ziegenfrischk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Ziegenfrischkäse"
                },
                {
                    "label": "Ziegenkäse",
                    "parameter": "passt-zu--Ziegenk%C3%A4se",
                    "attribute": "passt-zu",
                    "attributeValue": "Ziegenkäse"
                },
                {
                    "label": "Zigeunerspiess",
                    "parameter": "passt-zu--Zigeunerspiess",
                    "attribute": "passt-zu",
                    "attributeValue": "Zigeunerspiess"
                }
            ],
            "searchLabel": "Foodparings suchen"
        },
        {
            "type": "text_filters",
            "label": "Jahrgang",
            "attribute": "jahrgang-text",
            "includeSearch": true,
            "expandable": true,
            "items": [
                {
                    "label": "2023",
                    "parameter": "jahrgang-text--2023",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2023"
                },
                {
                    "label": "2022",
                    "parameter": "jahrgang-text--2022",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2022"
                },
                {
                    "label": "2021",
                    "parameter": "jahrgang-text--2021",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2021"
                },
                {
                    "label": "2020",
                    "parameter": "jahrgang-text--2020",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2020"
                },
                {
                    "label": "2019",
                    "parameter": "jahrgang-text--2019",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2019"
                },
                {
                    "label": "2018",
                    "parameter": "jahrgang-text--2018",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2018"
                },
                {
                    "label": "2017",
                    "parameter": "jahrgang-text--2017",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2017"
                },
                {
                    "label": "2016",
                    "parameter": "jahrgang-text--2016",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2016"
                },
                {
                    "label": "2015",
                    "parameter": "jahrgang-text--2015",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2015"
                },
                {
                    "label": "2014",
                    "parameter": "jahrgang-text--2014",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2014"
                },
                {
                    "label": "2013",
                    "parameter": "jahrgang-text--2013",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2013"
                },
                {
                    "label": "2012",
                    "parameter": "jahrgang-text--2012",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2012"
                },
                {
                    "label": "2010",
                    "parameter": "jahrgang-text--2010",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2010"
                },
                {
                    "label": "2009",
                    "parameter": "jahrgang-text--2009",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2009"
                },
                {
                    "label": "2008",
                    "parameter": "jahrgang-text--2008",
                    "attribute": "jahrgang-text",
                    "attributeValue": "2008"
                }
            ]
        },
        {
            "type": "text_filters",
            "label": "Rebsorten",
            "attribute": "rebsorten",
            "includeSearch": true,
            "expandable": true,
            "items": [
                {
                    "label": "Aglianico",
                    "parameter": "rebsorten--Aglianico",
                    "attribute": "rebsorten",
                    "attributeValue": "Aglianico"
                },
                {
                    "label": "Albariño",
                    "parameter": "rebsorten--Albari%C3%B1o",
                    "attribute": "rebsorten",
                    "attributeValue": "Albariño"
                },
                {
                    "label": "Amigne",
                    "parameter": "rebsorten--Amigne",
                    "attribute": "rebsorten",
                    "attributeValue": "Amigne"
                },
                {
                    "label": "Arinto",
                    "parameter": "rebsorten--Arinto",
                    "attribute": "rebsorten",
                    "attributeValue": "Arinto"
                },
                {
                    "label": "Arneis",
                    "parameter": "rebsorten--Arneis",
                    "attribute": "rebsorten",
                    "attributeValue": "Arneis"
                },
                {
                    "label": "Bacchus",
                    "parameter": "rebsorten--Bacchus",
                    "attribute": "rebsorten",
                    "attributeValue": "Bacchus"
                },
                {
                    "label": "Barbera",
                    "parameter": "rebsorten--Barbera",
                    "attribute": "rebsorten",
                    "attributeValue": "Barbera"
                },
                {
                    "label": "Blaufränkisch",
                    "parameter": "rebsorten--Blaufr%C3%A4nkisch",
                    "attribute": "rebsorten",
                    "attributeValue": "Blaufränkisch"
                },
                {
                    "label": "Cabernet Franc",
                    "parameter": "rebsorten--Cabernet%20Franc",
                    "attribute": "rebsorten",
                    "attributeValue": "Cabernet Franc"
                },
                {
                    "label": "Cabernet Sauvignon",
                    "parameter": "rebsorten--Cabernet%20Sauvignon",
                    "attribute": "rebsorten",
                    "attributeValue": "Cabernet Sauvignon"
                },
                {
                    "label": "Cannonau",
                    "parameter": "rebsorten--Cannonau",
                    "attribute": "rebsorten",
                    "attributeValue": "Cannonau"
                },
                {
                    "label": "Carignan",
                    "parameter": "rebsorten--Carignan",
                    "attribute": "rebsorten",
                    "attributeValue": "Carignan"
                },
                {
                    "label": "Cariñena",
                    "parameter": "rebsorten--Cari%C3%B1ena",
                    "attribute": "rebsorten",
                    "attributeValue": "Cariñena"
                },
                {
                    "label": "Carmenère",
                    "parameter": "rebsorten--Carmen%C3%A8re",
                    "attribute": "rebsorten",
                    "attributeValue": "Carmenère"
                },
                {
                    "label": "Chardonnay",
                    "parameter": "rebsorten--Chardonnay",
                    "attribute": "rebsorten",
                    "attributeValue": "Chardonnay"
                },
                {
                    "label": "Chasselas",
                    "parameter": "rebsorten--Chasselas",
                    "attribute": "rebsorten",
                    "attributeValue": "Chasselas"
                },
                {
                    "label": "Cinsault",
                    "parameter": "rebsorten--Cinsault",
                    "attribute": "rebsorten",
                    "attributeValue": "Cinsault"
                },
                {
                    "label": "Completer",
                    "parameter": "rebsorten--Completer",
                    "attribute": "rebsorten",
                    "attributeValue": "Completer"
                },
                {
                    "label": "Cornalin",
                    "parameter": "rebsorten--Cornalin",
                    "attribute": "rebsorten",
                    "attributeValue": "Cornalin"
                },
                {
                    "label": "Cortese",
                    "parameter": "rebsorten--Cortese",
                    "attribute": "rebsorten",
                    "attributeValue": "Cortese"
                },
                {
                    "label": "Corvina",
                    "parameter": "rebsorten--Corvina",
                    "attribute": "rebsorten",
                    "attributeValue": "Corvina"
                },
                {
                    "label": "Corvinone",
                    "parameter": "rebsorten--Corvinone",
                    "attribute": "rebsorten",
                    "attributeValue": "Corvinone"
                },
                {
                    "label": "Croatina",
                    "parameter": "rebsorten--Croatina",
                    "attribute": "rebsorten",
                    "attributeValue": "Croatina"
                },
                {
                    "label": "Diolinoir",
                    "parameter": "rebsorten--Diolinoir",
                    "attribute": "rebsorten",
                    "attributeValue": "Diolinoir"
                },
                {
                    "label": "Fernão Pires",
                    "parameter": "rebsorten--Fern%C3%A3o%20Pires",
                    "attribute": "rebsorten",
                    "attributeValue": "Fernão Pires"
                },
                {
                    "label": "Fiano",
                    "parameter": "rebsorten--Fiano",
                    "attribute": "rebsorten",
                    "attributeValue": "Fiano"
                },
                {
                    "label": "Früchte",
                    "parameter": "rebsorten--Fr%C3%BCchte",
                    "attribute": "rebsorten",
                    "attributeValue": "Früchte"
                },
                {
                    "label": "Gamaret",
                    "parameter": "rebsorten--Gamaret",
                    "attribute": "rebsorten",
                    "attributeValue": "Gamaret"
                },
                {
                    "label": "Gamay",
                    "parameter": "rebsorten--Gamay",
                    "attribute": "rebsorten",
                    "attributeValue": "Gamay"
                },
                {
                    "label": "Garanoir",
                    "parameter": "rebsorten--Garanoir",
                    "attribute": "rebsorten",
                    "attributeValue": "Garanoir"
                },
                {
                    "label": "Garganega",
                    "parameter": "rebsorten--Garganega",
                    "attribute": "rebsorten",
                    "attributeValue": "Garganega"
                },
                {
                    "label": "Garnacha",
                    "parameter": "rebsorten--Garnacha",
                    "attribute": "rebsorten",
                    "attributeValue": "Garnacha"
                },
                {
                    "label": "Garnacha Blanca",
                    "parameter": "rebsorten--Garnacha%20Blanca",
                    "attribute": "rebsorten",
                    "attributeValue": "Garnacha Blanca"
                },
                {
                    "label": "Garnacha Peluda",
                    "parameter": "rebsorten--Garnacha%20Peluda",
                    "attribute": "rebsorten",
                    "attributeValue": "Garnacha Peluda"
                },
                {
                    "label": "Gelber Muskateller",
                    "parameter": "rebsorten--Gelber%20Muskateller",
                    "attribute": "rebsorten",
                    "attributeValue": "Gelber Muskateller"
                },
                {
                    "label": "Gewürztraminer",
                    "parameter": "rebsorten--Gew%C3%BCrztraminer",
                    "attribute": "rebsorten",
                    "attributeValue": "Gewürztraminer"
                },
                {
                    "label": "Glera",
                    "parameter": "rebsorten--Glera",
                    "attribute": "rebsorten",
                    "attributeValue": "Glera"
                },
                {
                    "label": "Gouveio",
                    "parameter": "rebsorten--Gouveio",
                    "attribute": "rebsorten",
                    "attributeValue": "Gouveio"
                },
                {
                    "label": "Graciano",
                    "parameter": "rebsorten--Graciano",
                    "attribute": "rebsorten",
                    "attributeValue": "Graciano"
                },
                {
                    "label": "Grenache",
                    "parameter": "rebsorten--Grenache",
                    "attribute": "rebsorten",
                    "attributeValue": "Grenache"
                },
                {
                    "label": "Grillo",
                    "parameter": "rebsorten--Grillo",
                    "attribute": "rebsorten",
                    "attributeValue": "Grillo"
                },
                {
                    "label": "Grüner Veltliner",
                    "parameter": "rebsorten--Gr%C3%BCner%20Veltliner",
                    "attribute": "rebsorten",
                    "attributeValue": "Grüner Veltliner"
                },
                {
                    "label": "Heida",
                    "parameter": "rebsorten--Heida",
                    "attribute": "rebsorten",
                    "attributeValue": "Heida"
                },
                {
                    "label": "Humagne rouge",
                    "parameter": "rebsorten--Humagne%20rouge",
                    "attribute": "rebsorten",
                    "attributeValue": "Humagne rouge"
                },
                {
                    "label": "Incrocio Manzoni",
                    "parameter": "rebsorten--Incrocio%20Manzoni",
                    "attribute": "rebsorten",
                    "attributeValue": "Incrocio Manzoni"
                },
                {
                    "label": "Inzolia",
                    "parameter": "rebsorten--Inzolia",
                    "attribute": "rebsorten",
                    "attributeValue": "Inzolia"
                },
                {
                    "label": "Lagrein",
                    "parameter": "rebsorten--Lagrein",
                    "attribute": "rebsorten",
                    "attributeValue": "Lagrein"
                },
                {
                    "label": "Macabeo",
                    "parameter": "rebsorten--Macabeo",
                    "attribute": "rebsorten",
                    "attributeValue": "Macabeo"
                },
                {
                    "label": "Malbec",
                    "parameter": "rebsorten--Malbec",
                    "attribute": "rebsorten",
                    "attributeValue": "Malbec"
                },
                {
                    "label": "Malvasia Nera",
                    "parameter": "rebsorten--Malvasia%20Nera",
                    "attribute": "rebsorten",
                    "attributeValue": "Malvasia Nera"
                },
                {
                    "label": "Marsanne",
                    "parameter": "rebsorten--Marsanne",
                    "attribute": "rebsorten",
                    "attributeValue": "Marsanne"
                },
                {
                    "label": "Mazuelo",
                    "parameter": "rebsorten--Mazuelo",
                    "attribute": "rebsorten",
                    "attributeValue": "Mazuelo"
                },
                {
                    "label": "Merlot",
                    "parameter": "rebsorten--Merlot",
                    "attribute": "rebsorten",
                    "attributeValue": "Merlot"
                },
                {
                    "label": "Molinara",
                    "parameter": "rebsorten--Molinara",
                    "attribute": "rebsorten",
                    "attributeValue": "Molinara"
                },
                {
                    "label": "Montepulciano",
                    "parameter": "rebsorten--Montepulciano",
                    "attribute": "rebsorten",
                    "attributeValue": "Montepulciano"
                },
                {
                    "label": "Montonega",
                    "parameter": "rebsorten--Montonega",
                    "attribute": "rebsorten",
                    "attributeValue": "Montonega"
                },
                {
                    "label": "Morenillo",
                    "parameter": "rebsorten--Morenillo",
                    "attribute": "rebsorten",
                    "attributeValue": "Morenillo"
                },
                {
                    "label": "Moscatel",
                    "parameter": "rebsorten--Moscatel",
                    "attribute": "rebsorten",
                    "attributeValue": "Moscatel"
                },
                {
                    "label": "Moscato",
                    "parameter": "rebsorten--Moscato",
                    "attribute": "rebsorten",
                    "attributeValue": "Moscato"
                },
                {
                    "label": "Mourvèdre",
                    "parameter": "rebsorten--Mourv%C3%A8dre",
                    "attribute": "rebsorten",
                    "attributeValue": "Mourvèdre"
                },
                {
                    "label": "Müller-Thurgau",
                    "parameter": "rebsorten--M%C3%BCller-Thurgau",
                    "attribute": "rebsorten",
                    "attributeValue": "Müller-Thurgau"
                },
                {
                    "label": "Muscadelle",
                    "parameter": "rebsorten--Muscadelle",
                    "attribute": "rebsorten",
                    "attributeValue": "Muscadelle"
                },
                {
                    "label": "Muscat",
                    "parameter": "rebsorten--Muscat",
                    "attribute": "rebsorten",
                    "attributeValue": "Muscat"
                },
                {
                    "label": "Muskateller",
                    "parameter": "rebsorten--Muskateller",
                    "attribute": "rebsorten",
                    "attributeValue": "Muskateller"
                },
                {
                    "label": "Nebbiolo",
                    "parameter": "rebsorten--Nebbiolo",
                    "attribute": "rebsorten",
                    "attributeValue": "Nebbiolo"
                },
                {
                    "label": "Negroamaro",
                    "parameter": "rebsorten--Negroamaro",
                    "attribute": "rebsorten",
                    "attributeValue": "Negroamaro"
                },
                {
                    "label": "Nero d'Avola",
                    "parameter": "rebsorten--Nero%20d'Avola",
                    "attribute": "rebsorten",
                    "attributeValue": "Nero d'Avola"
                },
                {
                    "label": "Oseleta",
                    "parameter": "rebsorten--Oseleta",
                    "attribute": "rebsorten",
                    "attributeValue": "Oseleta"
                },
                {
                    "label": "Parellada",
                    "parameter": "rebsorten--Parellada",
                    "attribute": "rebsorten",
                    "attributeValue": "Parellada"
                },
                {
                    "label": "Parraleta",
                    "parameter": "rebsorten--Parraleta",
                    "attribute": "rebsorten",
                    "attributeValue": "Parraleta"
                },
                {
                    "label": "Petit Verdot",
                    "parameter": "rebsorten--Petit%20Verdot",
                    "attribute": "rebsorten",
                    "attributeValue": "Petit Verdot"
                },
                {
                    "label": "Petite Arvine",
                    "parameter": "rebsorten--Petite%20Arvine",
                    "attribute": "rebsorten",
                    "attributeValue": "Petite Arvine"
                },
                {
                    "label": "Petite Sirah",
                    "parameter": "rebsorten--Petite%20Sirah",
                    "attribute": "rebsorten",
                    "attributeValue": "Petite Sirah"
                },
                {
                    "label": "Pignola Valtellinese",
                    "parameter": "rebsorten--Pignola%20Valtellinese",
                    "attribute": "rebsorten",
                    "attributeValue": "Pignola Valtellinese"
                },
                {
                    "label": "Pinot Bianco",
                    "parameter": "rebsorten--Pinot%20Bianco",
                    "attribute": "rebsorten",
                    "attributeValue": "Pinot Bianco"
                },
                {
                    "label": "Pinot Blanc",
                    "parameter": "rebsorten--Pinot%20Blanc",
                    "attribute": "rebsorten",
                    "attributeValue": "Pinot Blanc"
                },
                {
                    "label": "Pinot Grigio",
                    "parameter": "rebsorten--Pinot%20Grigio",
                    "attribute": "rebsorten",
                    "attributeValue": "Pinot Grigio"
                },
                {
                    "label": "Pinot Meunier",
                    "parameter": "rebsorten--Pinot%20Meunier",
                    "attribute": "rebsorten",
                    "attributeValue": "Pinot Meunier"
                },
                {
                    "label": "Pinot Nero",
                    "parameter": "rebsorten--Pinot%20Nero",
                    "attribute": "rebsorten",
                    "attributeValue": "Pinot Nero"
                },
                {
                    "label": "Pinot Noir",
                    "parameter": "rebsorten--Pinot%20Noir",
                    "attribute": "rebsorten",
                    "attributeValue": "Pinot Noir"
                },
                {
                    "label": "Primitivo",
                    "parameter": "rebsorten--Primitivo",
                    "attribute": "rebsorten",
                    "attributeValue": "Primitivo"
                },
                {
                    "label": "Prugnola",
                    "parameter": "rebsorten--Prugnola",
                    "attribute": "rebsorten",
                    "attributeValue": "Prugnola"
                },
                {
                    "label": "Prugnolo Gentile",
                    "parameter": "rebsorten--Prugnolo%20Gentile",
                    "attribute": "rebsorten",
                    "attributeValue": "Prugnolo Gentile"
                },
                {
                    "label": "Raboso",
                    "parameter": "rebsorten--Raboso",
                    "attribute": "rebsorten",
                    "attributeValue": "Raboso"
                },
                {
                    "label": "Riesling",
                    "parameter": "rebsorten--Riesling",
                    "attribute": "rebsorten",
                    "attributeValue": "Riesling"
                },
                {
                    "label": "Riesling-Silvaner",
                    "parameter": "rebsorten--Riesling-Silvaner",
                    "attribute": "rebsorten",
                    "attributeValue": "Riesling-Silvaner"
                },
                {
                    "label": "Rondinella",
                    "parameter": "rebsorten--Rondinella",
                    "attribute": "rebsorten",
                    "attributeValue": "Rondinella"
                },
                {
                    "label": "Rossola",
                    "parameter": "rebsorten--Rossola",
                    "attribute": "rebsorten",
                    "attributeValue": "Rossola"
                },
                {
                    "label": "Roter Veltliner",
                    "parameter": "rebsorten--Roter%20Veltliner",
                    "attribute": "rebsorten",
                    "attributeValue": "Roter Veltliner"
                },
                {
                    "label": "Sangiovese",
                    "parameter": "rebsorten--Sangiovese",
                    "attribute": "rebsorten",
                    "attributeValue": "Sangiovese"
                },
                {
                    "label": "Sangiovese Grosso",
                    "parameter": "rebsorten--Sangiovese%20Grosso",
                    "attribute": "rebsorten",
                    "attributeValue": "Sangiovese Grosso"
                },
                {
                    "label": "Sauvignon Blanc",
                    "parameter": "rebsorten--Sauvignon%20Blanc",
                    "attribute": "rebsorten",
                    "attributeValue": "Sauvignon Blanc"
                },
                {
                    "label": "Scheurebe",
                    "parameter": "rebsorten--Scheurebe",
                    "attribute": "rebsorten",
                    "attributeValue": "Scheurebe"
                },
                {
                    "label": "Semillon",
                    "parameter": "rebsorten--Semillon",
                    "attribute": "rebsorten",
                    "attributeValue": "Semillon"
                },
                {
                    "label": "Shiraz",
                    "parameter": "rebsorten--Shiraz",
                    "attribute": "rebsorten",
                    "attributeValue": "Shiraz"
                },
                {
                    "label": "Sumoll",
                    "parameter": "rebsorten--Sumoll",
                    "attribute": "rebsorten",
                    "attributeValue": "Sumoll"
                },
                {
                    "label": "Sylvaner",
                    "parameter": "rebsorten--Sylvaner",
                    "attribute": "rebsorten",
                    "attributeValue": "Sylvaner"
                },
                {
                    "label": "Syrah",
                    "parameter": "rebsorten--Syrah",
                    "attribute": "rebsorten",
                    "attributeValue": "Syrah"
                },
                {
                    "label": "Tempranillo",
                    "parameter": "rebsorten--Tempranillo",
                    "attribute": "rebsorten",
                    "attributeValue": "Tempranillo"
                },
                {
                    "label": "Tempranillo Blanco",
                    "parameter": "rebsorten--Tempranillo%20Blanco",
                    "attribute": "rebsorten",
                    "attributeValue": "Tempranillo Blanco"
                },
                {
                    "label": "Tinta Cão",
                    "parameter": "rebsorten--Tinta%20C%C3%A3o",
                    "attribute": "rebsorten",
                    "attributeValue": "Tinta Cão"
                },
                {
                    "label": "Tinta del Pais",
                    "parameter": "rebsorten--Tinta%20del%20Pais",
                    "attribute": "rebsorten",
                    "attributeValue": "Tinta del Pais"
                },
                {
                    "label": "Tinta Roriz",
                    "parameter": "rebsorten--Tinta%20Roriz",
                    "attribute": "rebsorten",
                    "attributeValue": "Tinta Roriz"
                },
                {
                    "label": "Touriga Franca",
                    "parameter": "rebsorten--Touriga%20Franca",
                    "attribute": "rebsorten",
                    "attributeValue": "Touriga Franca"
                },
                {
                    "label": "Touriga Nacional",
                    "parameter": "rebsorten--Touriga%20Nacional",
                    "attribute": "rebsorten",
                    "attributeValue": "Touriga Nacional"
                },
                {
                    "label": "Trebbiano",
                    "parameter": "rebsorten--Trebbiano",
                    "attribute": "rebsorten",
                    "attributeValue": "Trebbiano"
                },
                {
                    "label": "Ugni Blanc",
                    "parameter": "rebsorten--Ugni%20Blanc",
                    "attribute": "rebsorten",
                    "attributeValue": "Ugni Blanc"
                },
                {
                    "label": "Verdejo",
                    "parameter": "rebsorten--Verdejo",
                    "attribute": "rebsorten",
                    "attributeValue": "Verdejo"
                },
                {
                    "label": "Vermentino",
                    "parameter": "rebsorten--Vermentino",
                    "attribute": "rebsorten",
                    "attributeValue": "Vermentino"
                },
                {
                    "label": "Viognier",
                    "parameter": "rebsorten--Viognier",
                    "attribute": "rebsorten",
                    "attributeValue": "Viognier"
                },
                {
                    "label": "Viosinho",
                    "parameter": "rebsorten--Viosinho",
                    "attribute": "rebsorten",
                    "attributeValue": "Viosinho"
                },
                {
                    "label": "Welschriesling",
                    "parameter": "rebsorten--Welschriesling",
                    "attribute": "rebsorten",
                    "attributeValue": "Welschriesling"
                },
                {
                    "label": "Xarel-lo",
                    "parameter": "rebsorten--Xarel-lo",
                    "attribute": "rebsorten",
                    "attributeValue": "Xarel-lo"
                },
                {
                    "label": "Zinfandel",
                    "parameter": "rebsorten--Zinfandel",
                    "attribute": "rebsorten",
                    "attributeValue": "Zinfandel"
                },
                {
                    "label": "Zweigelt",
                    "parameter": "rebsorten--Zweigelt",
                    "attribute": "rebsorten",
                    "attributeValue": "Zweigelt"
                }
            ],
            "searchLabel": "Rebsorten suchen"
        },
        {
            "type": "number_filter",
            "label": "Flaschengrösse (cl)",
            "attribute": "inhalt",
            "minimum": 1,
            "maximum": 200
        }
    ],
    "attributes": [
        "weintyp",
        "preis",
        "land",
        "strukturtext-v2",
        "appellation",
        "passt-zu",
        "jahrgang-text",
        "rebsorten",
        "inhalt"
    ]
}